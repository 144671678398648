import {
  faEdit,
  faTrashAlt,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Col, Label, Row, Collapse } from "reactstrap";
import { GRAY, GREEN } from "../../../constant/constant";
import "../../../styles/units.scss";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import commonService from "../../../services/common.service";
import UnitItemCard from "./UnitItemCard";
import { UnitCardProps } from "../../../models/units.model";

export default function UnitsCard(props: UnitCardProps) {
  const { t, i18n } = useTranslation();

  const [collapse, setCollapse] = useState(false);
  const [status, setStatus] = useState("Closed");
  const toggle = () => setCollapse(!collapse);
  const onEntering = () => setStatus("opened");
  const onExiting = () => setStatus("closed");

  const unitLocale = commonService.applyLocalization(
    "restaurant",
    "name",
    props.unit.locales
  );

  const getConversionFactor = (conversion: any) => {
    let conversionData: any = conversion;
    if (props.area !== "recipe") {
      const unitId: string = conversion.isRelateTo;
      let conversionUnit: any = conversion.to_conversions?.find(
        (unit: any) => unit.fromUnit === unitId
      );
      if (!conversionUnit)
        conversionUnit = conversion.from_conversions?.find(
          (unit: any) => unit.toUnit === unitId
        );
      if (!conversionUnit) return "";
      conversionData = conversionUnit;
    }
    let conversionFactor: number = conversionData.conversion_factor;
    if (conversionData.uuid === conversionData.toUnit)
      conversionFactor = 1 / conversionFactor;
    return String(conversionData.conversion_factor).includes(".999")
      ? Number(Math.round(conversionFactor))
      : Number(conversionFactor);
  };

  const isDisabled: boolean =
    props.area === "recipe" && !props.unit?.recipe_uuid;
  const conversionsList: Array<any> =
    props.unit?.conversions?.map((unit: any) => unit.uuid) || [];
  return (
    <div className="child-box mb-3">
      <Row className="app-row-padding">
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <h6
              onClick={toggle}
              className="flex-grow-1 text-left shadow-none btn btn-link font-weight-bold m-0"
            >
              <span className="bf-collapse-arrow-down mr-2 bf-collapse-arrow">
                <FontAwesomeIcon
                  icon={status === "opened" ? faChevronUp : faChevronDown}
                  style={{
                    color: GRAY,
                    fontSize: 16,
                  }}
                />
              </span>
              {unitLocale?.name} ({unitLocale?.symbol})
            </h6>
            <div className="flex-nowrap d-flex align-items-center">
              {props.area === "recipe" && props.unit?.recipe_uuid && (
                <div className="pr-4">
                  {props.unit?.gross_weight ? (
                    <span>
                      {" "}
                      ({props.unit?.gross_weight}) (
                      {props.unitName(props?.baseWeightUnit, "symbol")})
                    </span>
                  ) : (
                    <span className="text-warning">
                      {t("unit.noGrossWeightsAdded")}
                    </span>
                  )}
                </div>
              )}
              {!props.isSupplierOffering && !isDisabled && (
                <>
                  <span
                    onClick={() => props.setEditable()}
                    className="pointer-cursor"
                    data-toggle="tooltip"
                    data-placement="left"
                    title={t("common.edit")}
                  >
                    <FontAwesomeIcon style={{ color: GREEN }} icon={faEdit} />
                  </span>
                  {!props.unit?.conversions?.length &&
                    !props?.isItActiveUnit && (
                      <span
                        onClick={() => props.setDelete()}
                        className="pointer-cursor m-3"
                        data-toggle="tooltip"
                        data-placement="left"
                        title={t("common.delete")}
                      >
                        <FontAwesomeIcon
                          style={{ color: GRAY }}
                          icon={faTrashAlt}
                        />
                      </span>
                    )}
                </>
              )}
            </div>
          </div>
          <Collapse
            isOpen={collapse}
            onEntering={onEntering}
            onExiting={onExiting}
          >
            <div className="px-0 pt-2">
              <Label className="d-flex align-center font-weight-bold">
                {t("conversion.conversions")}
              </Label>
              {!props.unit?.conversions?.length && (
                <div className="p-3"> {t("conversion.noConversionAdded")}</div>
              )}
              {props.unit?.conversions?.map(
                (conversion: any, index: number) => {
                  return (
                    <div className="mx-n3" key={conversion.uuid}>
                      <UnitItemCard
                        key={props.unit.uuid + "_" + conversion.uuid}
                        allAnchorUnits={props.allAnchorUnits}
                        allUnits={props.allUnits}
                        area={props.area}
                        baseWeightUnit={props?.baseWeightUnit}
                        restaurantCode={props.restaurantCode}
                        cardItemData={conversion}
                        cardItemId={props.unit.uuid + "_" + conversion.uuid}
                        conversionsList={conversionsList}
                        getBaseUnitOptions={props?.getBaseUnitOptions}
                        getConversionFactor={getConversionFactor}
                        getUnitName={props.unitName}
                        handleUnitUnitEdit={props.handleUnitUnitEdit}
                        isParentGlobalUnit={isDisabled}
                        isNewCardItem={false}
                        onDeleteCard={props.setConversionDelete}
                        onNewUnitSelection={props.onNewUnitSelection}
                        onConversionCancel={props.onConversionCancel}
                        onSaveCard={props.saveConversion}
                        parentCardItemSelectionData={
                          props.cardItemSelectionData
                        }
                        saveButton={false}
                        tempUnit={props.tempUnit}
                        type={props.type}
                        unit={props.unit}
                      />
                    </div>
                  );
                }
              )}
              <div className="mx-n3">
                <UnitItemCard
                  key={"newCard_" + props.unit.uuid}
                  allAnchorUnits={props.allAnchorUnits}
                  allUnits={props.allUnits}
                  area={props.area}
                  baseWeightUnit={props?.baseWeightUnit}
                  restaurantCode={props.restaurantCode}
                  cardItemData={{ type: "newCard" }}
                  cardItemId={"newCard_" + props.unit.uuid}
                  conversionsList={conversionsList}
                  getBaseUnitOptions={props?.getBaseUnitOptions}
                  getConversionFactor={getConversionFactor}
                  getUnitName={props.unitName}
                  handleUnitUnitEdit={props.handleUnitUnitEdit}
                  isNewCardItem={true}
                  onDeleteCard={props.setConversionDelete}
                  onConversionCancel={props.onConversionCancel}
                  onNewUnitSelection={props.onNewUnitSelection}
                  onSaveCard={props.saveConversion}
                  parentCardItemSelectionData={props.cardItemSelectionData}
                  saveButton={false}
                  tempUnit={props.tempUnit}
                  type={props.type}
                  unit={props.unit}
                />
              </div>
            </div>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
}

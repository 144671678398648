import "@mobiscroll/react/dist/css/mobiscroll.min.css";

import mobiscroll from "@mobiscroll/react";

import commonService from "./common.service";

mobiscroll.settings = {
  theme: "ios",
  themeVariant: "light",
};

export default function handleResponse(res: any) {
  let flattenObject = function (ob: any) {
    let toReturn: any = {};
    let errorString: any = "";
    for (let i in ob) {
      if (!ob.hasOwnProperty(i)) continue;
      if (typeof ob[i] == "object") {
        toReturn[i] = flattenObject(ob[i]);
      } else {
        toReturn[i] = ob[i];
      }
    }
    for (var key in toReturn) {
      errorString =
        errorString === ""
          ? errorString + toReturn[key]
          : errorString + ", " + toReturn[key];
    }
    return errorString;
  };
  if (!res.ok) {
    if (res.status === 400 && !!res.data && !!res.data.error_description) {
      commonService.toastService(res.data.error_description, "danger");
    }
    if (
      res.status === 400 &&
      !!res.data &&
      !!res.data.errors &&
      Object.values(res.data.errors)
    ) {
      const hasSingleError = Object.keys(res.data.errors).length === 1;
      if (typeof res.data.errors === "string") {
        let errorMessage: string = flattenObject(res.data.errors);
        commonService.toastService(errorMessage, "danger");
      } else if (hasSingleError && typeof Object.values(res.data.errors) === "string") {
        const [errorMessage] = Object.values(res.data.errors);
        commonService.toastService(errorMessage, "danger");
      } else if (res.data.errors[0]) {
        commonService.toastService(res.data.errors[0], "danger");
      } else {
        commonService.toastService("", "danger", JSON.stringify(res.data));
      }
    } else if (res.status === 401 && res.data && res.data.error_description) {
      commonService.toastService(res.data.error_description, "danger");
      // Clear local data if Access tocken is invalid
      // commonService.clearUserData();
    } else if (res.status === 403 && !!res.data && res.data.errors) {
      commonService.toastService(res.data.errors[0], "danger");
    } else if (res.status === 404 && !!res.data && res.data.errors) {
      commonService.toastService(res.data.errors[0], "danger");
    } else if (res.status === 500 && !!res.data && res.data.errors) {
      commonService.toastService(res.data.errors[0], "danger");
    } else {
      commonService.toastService("", "danger", JSON.stringify(res.data));
    }
  } else {
    if (
      res.data &&
      res.data.messages &&
      res.data.messages.length > 0 &&
      res.data.warnings &&
      res.data.warnings.length > 0
    ) {
      let successMessage = "";
      res.data.messages.forEach((message: any) => {
        successMessage = successMessage + message;
      });
      commonService.toastService(successMessage, "success");
      let warningMessage = "";
      res.data.warnings.forEach((message: any) => {
        warningMessage = warningMessage + message;
      });
      if (warningMessage !== "") {
        setTimeout(() => {
          commonService.toastService(warningMessage, "warning");
        }, 5000);
      }
    } else {
      if (
        (res.data && res.data.messages && res.data.messages.length > 0) ||
        (res.data.status && res.data.flash)
      ) {
        let successMessage = "";
        if (res.data.messages)
          res.data.messages.forEach((message: any) => {
            successMessage = successMessage + message;
          });
        else {
          successMessage = res.data.flash;
        }

        commonService.toastService(successMessage, "success");
      } else if (
        res.data &&
        res.data.warnings &&
        res.data.warnings.length > 0
      ) {
        let successMessage = "";
        res.data.warnings.forEach((message: any) => {
          successMessage = successMessage + message;
        });
        commonService.toastService(successMessage, "warning");
      }
    }
  }
  return res;
}

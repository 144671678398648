import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import LoaderComponent from "../../components/loader/loader";
import OperateHeaderComponent from "../../components/navigation/navigation-header/operate-header/operate-header";
import OperateLeftComponent from "../../components/navigation/navigation-left/operate-navigation/operate-navigation";
import { connect } from "react-redux";
import CorrectedPaymentTransactions from "../../components/reports/correctedPaymentTransactions";
import EndOfDayRestaurantClosure from "../../components/reports/endOfDayRestaurantClosure";
import PaymentTransactionsQualityCheckLast30Days from "../../components/reports/paymentTransactionsQualityCheckLast30Days";
import OpenTransactionQualityCheck from "../../components/reports/openTransactionQualityCheck";
import RoomClosureByDate from "../../components/reports/roomClosureByDate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GRAY, ORDER_TYPE } from "../../constant/constant";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { reportInitState, reportState } from "../../models/reports.model";
import {
  getOpenTransactionQltyRequest,
  getPaymentTransactionQltyRequest,
  getRestaurantClosureRequest,
  getRoomClosureRequest,
  printGuestGroupReportRequest,
} from "../../redux/actions/report.action";
import { roomRequest } from "../../redux/actions/room.action";
import { RESTAURANT_REQUEST } from "../../config/api.config";

import {
  getCorrectedTransactions,
  getListOfGuestGroups,
  getRevenuePerPaymentType,
  getRevenuePerLabel,
  getRevenuePerProductGroup,
  getRevenuePerRecipe,
  getReportingTemplates,
  getCostReports,
  getServiceSetReport,
  getInventoryReports,
  getOnlineInventoryReports,
  getListOfProducedBatches,
  getListOfOrderRequests,
  exportOnlineInventoryReports,
  getSupplierOrders,
} from "../../services/report.service";
import { allRecipe as getAllRecipes } from "../../services/recipe.service";
import { label as getLabelList } from "../../services/labels.service";

import ListOfGuestGroups from "../../components/reports/listOfGuestGroups";
import commonService from "../../services/common.service";
import RevenuePerLabel from "../../components/reports/revenuePerLabel";
import RevenuePerRecipe from "../../components/reports/revenuePerRecipe";
import RevenuePerPaymentType from "../../components/reports/revenuePerPaymentType";
import RevenuePerProductGroup from "../../components/reports/revenuePerProductGroup";
import { create } from "apisauce";
import { BASE_URL } from "../../config/app.config";
import { recipePrinterRequest } from "../../redux/actions/recipe.printer.action";
import handleResponse from "../../services/response.service";
import { getSuppliedRestaurants } from "../../services/restaurant.service";
import ListOfProducedBatches from "../../components/reports/listOfProducedBatches";
import ListOfRequests from "../../components/reports/listOfRequests";
import OnlineInventoryList from "../../components/reports/onlineInventoryList";
import SupplierOrders from "../../components/reports/supplierOrders";
import {
  getRestaurantClients,
  getRestaurantSuppliers,
  getSuppliersWithServiceSetInventoryAccess,
} from "../../services/supplier.restaurants.service";
import { servicesetRequest } from "../../redux/actions/serviceset.action";

const api = create({
  baseURL: BASE_URL,
});
class ReportsPage extends Component<any> {
  state: reportState;
  linkRef = React.createRef();
  supplierRestaurants: Array<any> = [];
  supplierList: Array<any> = [];
  restaurantClients: Array<any> = [];
  constructor(props: any) {
    super(props);
    this.state = reportInitState;
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.getRooms({
      restaurantuuid: this.props.match.params.uuid,
    });
    this.getLabels();
    this.getRecipes();
    this.props.getPrinter({ restaurantuuid: this.props.match.params.uuid });

    this.getReportTemplates();
    this.getSupplyRestaurants();
    this.getSuppliers();
    this.setState({ selectedRestaurant: this.props.match.params.uuid });
    this.getRestaurantClients();
    this.getSuppliersHavingServiceSetInventoryAccess();
    this.props.getServiceSet({ restaurantuuid: this.props.match.params.uuid });
  }

  getRecipes = () => {
    getAllRecipes(RESTAURANT_REQUEST, {
      credentials: {
        restaurantuuid: this.props?.match?.params.uuid,
      },
    }).then((response: any) => {
      this.setState({
        recipeList: response?.data,
      });
    });
  };

  getLabels = () => {
    getLabelList(RESTAURANT_REQUEST, {
      credentials: {
        restaurantuuid: this.props?.match?.params.uuid,
      },
    }).then((response: any) => {
      this.setState({
        labelList: response?.data,
      });
    });
  };

  getSupplyRestaurants = () => {
    getSuppliedRestaurants(
      RESTAURANT_REQUEST,
      this.props?.match?.params.uuid
    ).then((response: any) => {
      const responseData: any = handleResponse(response);
      if (responseData?.ok) {
        this.supplierRestaurants = [
          ...this.supplierRestaurants,
          ...responseData.data,
        ];
      }
    });
  };

  getRestaurantClients = () => {
    getRestaurantClients(RESTAURANT_REQUEST, {
      credentials: {
        restaurantuuid: this.props.match.params.uuid,
      },
    }).then((data) => {
      const responseData: any = handleResponse(data);
      if (responseData?.ok) {
        this.restaurantClients = responseData.data;
      }
    });
  };

  getSuppliers = () => {
    getRestaurantSuppliers(RESTAURANT_REQUEST, {
      credentials: {
        restaurantuuid: this.props.match.params.uuid,
      },
    }).then((data) => {
      const responseData: any = handleResponse(data);
      if (responseData?.ok) {
        this.supplierList = responseData.data;
      }
    });
  };

  getSuppliersHavingServiceSetInventoryAccess = () => {
    getSuppliersWithServiceSetInventoryAccess(RESTAURANT_REQUEST, {
      credentials: {
        restaurantuuid: this.props.match.params.uuid,
      },
    }).then((data) => {
      const responseData: any = handleResponse(data);
      if (responseData?.ok) {
        this.supplierRestaurants = [
          ...this.supplierRestaurants,
          ...responseData.data,
        ];
      }
    });
  };

  withCurrentRestaurant = (suppliers: Array<any>): Array<any> => {
    return [
      ...[JSON.parse(localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "")],
      ...suppliers,
    ];
  };

  confirmGuestPrint = () => {
    const formData = {
      type: this.state.guestPrintData.type,
      printer: this.state.selectedPrinter,
      uuid: this.state.guestPrintData.data.uuid,
    };

    this.props.printGuestGroup({
      restaurantuuid: this.props.match.params.uuid,
      data: formData,
    });
    this.togglePrinterModal();
  };

  getReportTemplates = () => {
    this.setState({ isFetching: true });
    const payLoad = {
      credentials: {
        restaurantuuid: this.props.match.params.uuid,
      },
    };
    getReportingTemplates(RESTAURANT_REQUEST, payLoad)
      .then((data: any) => {
        if (data.status === 200) {
          this.setState({
            reportTemplates: data.data,
            selectedTemplate: data.data?.[0]?.uuid || "",
            isFetching: false,
          });
        } else {
          this.setState({ isFetching: false });
        }
      })
      .catch((error: any) => this.setState({ isFetching: false }));
  };

  handleDateChange = (data: Date, field: string) => {
    if (!!data && field === "")
      this.setState({
        selectedDate: data,
      });
    else if (field === "fromDate")
      this.setState({
        fromDate: data,
      });
    else
      this.setState({
        toDate: data,
      });
  };

  handleGuestPrint = (data: any, type: string) => {
    this.setState({
      guestPrintData: {
        type,
        data,
      },
    });
    this.togglePrinterModal();
  };

  handleSelection = (element: any) => {
    this.setState({
      [element.target.name]: element.target.value,
    });
    if (element.target.name === "reportSelection") {
      this.setState({
        selectedRoom: "",
        fromDate: new Date(),
        toDate: new Date(),
      });
    }
  };

  renderReports = (selection: string) => {
    let table;
    switch (selection) {
      case "restaurantClosureByDate":
        table = (
          <EndOfDayRestaurantClosure
            data={this.props.reportRestaurantClosure}
          />
        );
        break;
      case "roomClosureByDate":
        table = (
          <RoomClosureByDate
            data={this.props.reportRoomClosure}
            allRooms={this.props.room}
          />
        );
        break;
      case "openTransactionQualityChk":
        table = (
          <OpenTransactionQualityCheck
            data={this.props.reportOpenTransactionQlty}
          />
        );
        break;
      case "PaymentTransactionsQualityCheckLast30Days":
        table = (
          <PaymentTransactionsQualityCheckLast30Days
            data={this.props.reportPaymentTransactionQlty.data}
          />
        );
        break;
      case "correctedPaymentTransactions":
        table = <CorrectedPaymentTransactions data={this.state.reportData} />;
        break;
      case "revenuePerProductGroup":
        table = <RevenuePerProductGroup data={this.state.reportData} />;
        break;
      case "revenuePerLabel":
        table = (
          <RevenuePerLabel
            data={this.state.reportData}
            labels={this.state.labelList}
          />
        );
        break;
      case "revenuePerPaymentType":
        table = <RevenuePerPaymentType data={this.state.reportData} />;
        break;
      case "revenuePerRecipe":
        table = <RevenuePerRecipe data={this.state.reportData} />;
        break;
      case "listOfGuestGroup":
        table = (
          <ListOfGuestGroups
            data={this.state.reportData}
            handleGuestPrint={this.handleGuestPrint}
          />
        );
        break;
      case "listOfProducedBatches":
        table = <ListOfProducedBatches data={this.state.reportData} />;
        break;
      case "requestsForTimeFrame":
        table = (
          <ListOfRequests
            data={this.state.reportData}
            handleGuestPrint={this.handleGuestPrint}
          />
        );
        break;
      case "supplierOrdersForTimeframe":
        table = <SupplierOrders data={this.state.reportData} />;
        break;
      case "onlineInventoryReport":
        table = (
          <OnlineInventoryList
            data={this.state.reportData}
            downloadReport={this.downloadReport}
          />
        );
        break;
    }
    return table;
  };

  runReport = () => {
    this.setState({
      isFetching: true,
    });
    switch (this.state.reportSelection) {
      case "restaurantClosureByDate":
        this.props.getRestaurantClosure({
          restaurantuuid: this.props.match.params.uuid,
          data: {
            date: moment(this.state.selectedDate, "YYYY-MM-DD|kk:mm").format(),
          },
        });
        this.setState({
          isFetching: false,
        });
        break;

      case "roomClosureByDate":
        this.props.getRoomClosure({
          restaurantuuid: this.props.match.params.uuid,
          data: {
            date: moment(this.state.selectedDate, "YYYY-MM-DD|kk:mm").format(),
            room: this.state.selectedRoom,
          },
        });
        this.setState({
          isFetching: false,
        });
        break;

      case "openTransactionQualityChk":
        this.props.getOpenTransactionQlty({
          restaurantuuid: this.props.match.params.uuid,
        });
        this.setState({
          isFetching: false,
        });
        break;

      case "PaymentTransactionsQualityCheckLast30Days":
        this.props.getPaymentTransactionQlty({
          restaurantuuid: this.props.match.params.uuid,
        });
        this.setState({
          isFetching: false,
        });
        break;
      case "correctedPaymentTransactions": {
        getCorrectedTransactions(RESTAURANT_REQUEST, {
          credentials: {
            restaurantuuid: this.props.match.params.uuid,
            data: {
              fromDate: moment(
                this.state.fromDate,
                "YYYY-MM-DD|kk:mm"
              ).format(),
              toDate: moment(this.state.toDate, "YYYY-MM-DD|kk:mm").format(),
            },
          },
        })
          .then((response: any) => {
            this.setState({
              isFetching: false,
              reportData: response?.data?.data?.correctedPaymentTransactions,
            });
          })
          .catch((error: any) => {
            this.setState({
              isFetching: false,
            });
          });
        break;
      }
      case "revenuePerPaymentType": {
        getRevenuePerPaymentType(RESTAURANT_REQUEST, {
          credentials: {
            restaurantuuid: this.props.match.params.uuid,
            data: {
              fromDate: moment(
                this.state.fromDate,
                "YYYY-MM-DD|kk:mm"
              ).format(),
              toDate: moment(this.state.toDate, "YYYY-MM-DD|kk:mm").format(),
            },
          },
        })
          .then((response: any) => {
            this.setState({
              isFetching: false,
              reportData: response?.data?.data,
            });
          })
          .catch((error: any) => {
            this.setState({
              isFetching: false,
            });
          });
        break;
      }

      case "revenuePerLabel": {
        getRevenuePerLabel(RESTAURANT_REQUEST, {
          credentials: {
            restaurantuuid: this.props.match.params.uuid,
            data: {
              fromDate: moment(
                this.state.fromDate,
                "YYYY-MM-DD|kk:mm"
              ).format(),
              toDate: moment(this.state.toDate, "YYYY-MM-DD|kk:mm").format(),
              room: !!this.state.selectedRoom
                ? this.state.selectedRoom
                : undefined,
            },
          },
        })
          .then((response: any) => {
            this.setState({
              isFetching: false,
              reportData: response?.data?.data,
            });
          })
          .catch((error: any) => {
            this.setState({
              isFetching: false,
            });
          });
        break;
      }

      case "revenuePerRecipe": {
        getRevenuePerRecipe(RESTAURANT_REQUEST, {
          credentials: {
            restaurantuuid: this.props.match.params.uuid,
            data: {
              fromDate: moment(
                this.state.fromDate,
                "YYYY-MM-DD|kk:mm"
              ).format(),
              toDate: moment(this.state.toDate, "YYYY-MM-DD|kk:mm").format(),
            },
          },
        })
          .then((response: any) => {
            this.setState({
              isFetching: false,
              reportData: response.data?.data,
            });
          })
          .catch((error: any) => {
            this.setState({
              isFetching: false,
            });
          });
        break;
      }

      case "revenuePerProductGroup": {
        getRevenuePerProductGroup(RESTAURANT_REQUEST, {
          credentials: {
            restaurantuuid: this.props.match.params.uuid,
            data: {
              fromDate: moment(
                this.state.fromDate,
                "YYYY-MM-DD|kk:mm"
              ).format(),
              toDate: moment(this.state.toDate, "YYYY-MM-DD|kk:mm").format(),
              room: !!this.state.selectedRoom
                ? this.state.selectedRoom
                : undefined,
            },
          },
        })
          .then((response: any) => {
            this.setState({
              isFetching: false,
              reportData: response.data?.data,
            });
          })
          .catch((error: any) => {
            this.setState({
              isFetching: false,
            });
          });
        break;
      }
      case "listOfGuestGroup": {
        getListOfGuestGroups(RESTAURANT_REQUEST, {
          credentials: {
            restaurantuuid: this.props.match.params.uuid,
            data: {
              fromDate: moment(
                this.state.fromDate,
                "YYYY-MM-DD|kk:mm"
              ).format(),
              toDate: moment(this.state.toDate, "YYYY-MM-DD|kk:mm").format(),
            },
          },
        })
          .then((response: any) => {
            this.setState({
              isFetching: false,
              reportData: response?.data?.data,
            });
          })
          .catch((error: any) => {
            this.setState({
              isFetching: false,
            });
          });
        break;
      }
      case "requestsForTimeFrame": {
        getListOfOrderRequests(RESTAURANT_REQUEST, {
          credentials: {
            restaurantuuid: this.props.match.params.uuid,
            data: {
              fromDate: moment(
                this.state.fromDate,
                "YYYY-MM-DD|kk:mm"
              ).format(),
              toDate: moment(this.state.toDate, "YYYY-MM-DD|kk:mm").format(),
              requestType: !!this.state.requestType
                ? this.state.requestType
                : undefined,
              clientRestaurant: !!this.state.clientRestaurant
                ? this.state.clientRestaurant
                : undefined,
            },
          },
        })
          .then((response: any) => {
            this.setState({
              isFetching: false,
              reportData: response?.data?.data,
            });
          })
          .catch((error: any) => {
            this.setState({
              isFetching: false,
            });
          });
        break;
      }
      case "supplierOrdersForTimeframe": {
        getSupplierOrders(RESTAURANT_REQUEST, {
          credentials: {
            restaurantuuid: this.props.match.params.uuid,
            data: {
              fromDate: moment(
                this.state.fromDate,
                "YYYY-MM-DD|kk:mm"
              ).format(),
              toDate: moment(this.state.toDate, "YYYY-MM-DD|kk:mm").format(),
              supplier: !!this.state.selectedSupplier
                ? this.state.selectedSupplier
                : undefined,
            },
          },
        })
          .then((response: any) => {
            this.setState({
              isFetching: false,
              reportData: response?.data?.data,
            });
          })
          .catch((error: any) => {
            this.setState({
              isFetching: false,
            });
          });
        break;
      }
      case "listOfProducedBatches": {
        getListOfProducedBatches(RESTAURANT_REQUEST, {
          credentials: {
            restaurantuuid: this.props.match.params.uuid,
            data: {
              fromDate: moment(
                this.state.fromDate,
                "YYYY-MM-DD|kk:mm"
              ).format(),
              toDate: moment(this.state.toDate, "YYYY-MM-DD|kk:mm").format(),
            },
          },
        })
          .then((response: any) => {
            this.setState({
              isFetching: false,
              reportData: response?.data?.data,
            });
          })
          .catch((error: any) => {
            this.setState({
              isFetching: false,
            });
          });
        break;
      }
      case "managementReportForTimeFrame":
        api.setHeaders(commonService.header());
        api
          .post(
            "/api/1.0/restaurant/" +
              this.props.match.params.uuid +
              "/management-report",
            {
              fromDate: moment(
                this.state.fromDate,
                "YYYY-MM-DD|kk:mm"
              ).format(),
              toDate: moment(this.state.toDate, "YYYY-MM-DD|kk:mm").format(),
              template: this.state.selectedTemplate,
            },
            { responseType: "arraybuffer" }
          )
          .then((response: any) => {
            this.buildDownloadable(response, "ManagementReportForTimeFrame");
          })
          .catch((err) => {
            this.setState({
              isFetching: false,
            });
          });

        break;
      case "costReport":
        getCostReports(RESTAURANT_REQUEST, {
          credentials: {
            restaurantuuid: this.props.match.params.uuid,
            data: {
              template: this.state.selectedTemplate,
            },
          },
        })
          .then((response: any) => {
            this.buildDownloadable(response, "CostReport");
          })
          .catch(() => {
            this.setState({
              isFetching: false,
            });
          });
        break;
      case "serviceSetReport":
        getServiceSetReport(RESTAURANT_REQUEST, {
          credentials: {
            restaurantuuid: this.props.match.params.uuid,
            data: {
              template: this.state.selectedTemplate,
              serviceSet: this.state.serviceSet,
            },
          },
        })
          .then((response: any) => {
            this.buildDownloadable(response, "serviceSetReport");
          })
          .catch(() => {
            this.setState({
              isFetching: false,
            });
          });
        break;
      case "inventoryReport":
        getInventoryReports(RESTAURANT_REQUEST, {
          credentials: {
            restaurantuuid: this.props.match.params.uuid,
            data: {
              fromDate: moment(
                this.state.fromDate,
                "YYYY-MM-DD|kk:mm"
              ).format(),
              toDate: moment(this.state.toDate, "YYYY-MM-DD|kk:mm").format(),
              template: this.state.selectedTemplate,
            },
          },
        })
          .then((response: any) => {
            this.buildDownloadable(response, "InventoryReport");
          })
          .catch(() => {
            this.setState({
              isFetching: false,
            });
          });
        break;
      case "onlineInventoryReport":
        const selecteRestaurant = this.withCurrentRestaurant(
          this.supplierRestaurants
        ).find((r) => r.uuid === this.state.selectedRestaurant);
        getOnlineInventoryReports(RESTAURANT_REQUEST, {
          credentials: {
            restaurantuuid: this.props.match.params.uuid,
            data: {
              selectedRestaurant: this.state.selectedRestaurant,
              restaurantInventoryAccess:
                selecteRestaurant?.restaurantInventoryAccess || undefined,
              serviceSetInventoryAccess:
                selecteRestaurant?.serviceSetInventoryAccess || undefined,
              template: this.state.selectedTemplate,
            },
          },
        })
          .then((response: any) => {
            this.setState({
              isFetching: false,
              reportData: response?.data?.data,
            });
          })
          .catch(() => {
            this.setState({
              isFetching: false,
            });
          });
        break;
    }
    this.setState({
      activeReport: this.state.reportSelection,
    });
  };

  buildDownloadable = (response: any, report: string) => {
    if (response?.ok || response.status === 200) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${report}_${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    handleResponse(response);
    this.setState({ isFetching: false });
  };

  downloadReport = (report: string) => {
    switch (report) {
      case "onlineInventoryReport":
        const selecteRestaurant = this.withCurrentRestaurant(
          this.supplierRestaurants
        ).find((r) => r.uuid === this.state.selectedRestaurant);
        exportOnlineInventoryReports(RESTAURANT_REQUEST, {
          credentials: {
            restaurantuuid: this.props.match.params.uuid,
            data: {
              selectedRestaurant: this.state.selectedRestaurant,
              restaurantInventoryAccess:
                selecteRestaurant?.restaurantInventoryAccess || undefined,
              serviceSetInventoryAccess:
                selecteRestaurant?.serviceSetInventoryAccess || undefined,
              template: this.state.selectedTemplate,
            },
          },
        })
          .then((response: any) => {
            this.buildDownloadable(response, report);
          })
          .catch(() => {
            this.setState({
              isFetching: false,
            });
          });
        break;
    }
  };

  togglePrinterModal = () => {
    this.setState({
      isPrinterModalOpen: !this.state.isPrinterModalOpen,
    });
  };

  render() {
    const { t } = this.props;
    const options: Array<any> = [
      {
        label: t("reports.endOfDayRestaurantClosureByDate"),
        value: "restaurantClosureByDate",
      },
      {
        label: t("reports.roomClosureByDate"),
        value: "roomClosureByDate",
      },
      {
        label: t("reports.openTransactionQualityChk"),
        value: "openTransactionQualityChk",
      },
      {
        label: t("reports.paymentTransactionsQualityCheckLast30Days"),
        value: "PaymentTransactionsQualityCheckLast30Days",
      },
      {
        label: t("reports.correctedPaymentTransactionsForTimeFrame"),
        value: "correctedPaymentTransactions",
      },
      {
        label: t("reports.revenuePerProductGroup"),
        value: "revenuePerProductGroup",
      },
      {
        label: t("reports.revenuePerLabel"),
        value: "revenuePerLabel",
      },
      {
        label: t("reports.revenuePerPaymentType"),
        value: "revenuePerPaymentType",
      },
      {
        label: t("reports.listOfGuestGroup"),
        value: "listOfGuestGroup",
      },
      {
        label: t("reports.managementReportForTimeFrame"),
        value: "managementReportForTimeFrame",
        template: true,
      },
      {
        label: t("reports.requestsForTimeFrame"),
        value: "requestsForTimeFrame",
      },
      {
        label: t("reports.supplierOrdersForTimeframe"),
        value: "supplierOrdersForTimeframe",
      },
      {
        label: t("reports.costReports"),
        value: "costReport",
        template: true,
      },
      {
        label: t("reports.inventoryReports"),
        value: "inventoryReport",
        template: true,
      },
      {
        label: t("reports.onlineInventoryReport"),
        value: "onlineInventoryReport",
        template: true,
      },
      {
        label: t("reports.ProducedBatchesPerTimeframe"),
        value: "listOfProducedBatches",
      },
      {
        label: t("reports.serviceSetReport"),
        value: "serviceSetReport",
        template: true,
      },
    ];
    return (
      <React.Fragment>
        <div className="container-fluid">
          <LoaderComponent
            display={this.props.isFetching || this.state.isFetching}
          />
          <Modal isOpen={this.state.isPrinterModalOpen}>
            <ModalHeader
              toggle={this.togglePrinterModal}
              className="modal-header"
            >
              {t("printer.printers")}
            </ModalHeader>
            <ModalBody>
              {(!this.props.isFetching && this.props?.printers?.length === 0) ||
              Object.keys(this.props?.printers)?.length === 0 ? (
                t("common.noRecords")
              ) : (
                <ListGroup>
                  {this.props?.printers?.map((printer: any, key: number) => {
                    return (
                      <ListGroupItem key={"printer" + key}>
                        <Label className="b-cursor-p d-flex align-items-center">
                          <Input
                            name="printer"
                            type="radio"
                            className="ml-0 mr-1 b-pos-rel"
                            onChange={() =>
                              this.setState({
                                selectedPrinter: printer.uuid,
                              })
                            }
                            checked={
                              this.state.selectedPrinter === printer.uuid
                            }
                          />
                          <span className=" ">{printer.name}</span>
                        </Label>
                      </ListGroupItem>
                    );
                  })}
                </ListGroup>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.togglePrinterModal}>
                {t("common.cancel")}
              </Button>
              <Button
                color="info"
                onClick={this.confirmGuestPrint}
                disabled={!!!this.state.selectedPrinter}
              >
                {t("common.print")}
              </Button>
            </ModalFooter>
          </Modal>
          <OperateHeaderComponent operate={"operate"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <OperateLeftComponent
                display={"reports"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={8} lg={6}>
              <div className="white-box mb-3">
                <header className="px-3 mb-4">
                  <h4>{t("common.reports")}</h4>
                  <div className="d-flex">
                    <Input
                      type="select"
                      name="reportSelection"
                      id="reportSelection"
                      className="mr-3  b-min-width-150"
                      onChange={this.handleSelection}
                    >
                      <option value="">{t("common.select")}</option>
                      {options.map((option: any) =>
                        !option?.template ||
                        (option?.template &&
                          this.state.reportTemplates.length > 0) ? (
                          <option value={option.value}>{option.label}</option>
                        ) : null
                      )}
                    </Input>
                    {(this.state.reportSelection ===
                      "restaurantClosureByDate" ||
                      this.state.reportSelection === "roomClosureByDate") && (
                      <InputGroup className="flex-nowrap mr-2 b-min-width-150">
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          disabledKeyboardNavigation
                          selected={this.state.selectedDate}
                          onChange={(value: any) =>
                            this.handleDateChange(value, "")
                          }
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <FontAwesomeIcon
                              style={{ color: GRAY }}
                              icon={faCalendar}
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    )}
                    {[
                      "managementReportForTimeFrame",
                      "costReport",
                      "inventoryReport",
                      "onlineInventoryReport",
                      "serviceSetReport",
                    ].includes(this.state.reportSelection) && (
                      <Input
                        type="select"
                        name="selectedTemplate"
                        id="selectedTemplate"
                        className="mr-3"
                        onChange={this.handleSelection}
                      >
                        {this.state.reportTemplates?.map((template: any) => {
                          return (
                            <option value={template.uuid}>
                              {template.filename}
                            </option>
                          );
                        })}
                      </Input>
                    )}
                    {(this.state.reportSelection === "revenuePerProductGroup" ||
                      this.state.reportSelection === "revenuePerLabel" ||
                      this.state.reportSelection === "revenuePerPaymentType" ||
                      this.state.reportSelection === "revenuePerRecipe" ||
                      this.state.reportSelection === "listOfGuestGroup" ||
                      this.state.reportSelection === "listOfProducedBatches" ||
                      this.state.reportSelection === "inventoryReport" ||
                      this.state.reportSelection === "requestsForTimeFrame" ||
                      this.state.reportSelection ===
                        "correctedPaymentTransactions" ||
                      this.state.reportSelection ===
                        "managementReportForTimeFrame" ||
                      this.state.reportSelection ===
                        "supplierOrdersForTimeframe") && (
                      <>
                        <InputGroup className="flex-nowrap mr-2 b-min-width-150">
                          <DatePicker
                            className={
                              this.state.fromDate.getTime() >
                              this.state.toDate.getTime()
                                ? "form-control briefing-input-error "
                                : "form-control"
                            }
                            dateFormat="dd/MM/yyyy"
                            disabledKeyboardNavigation
                            selected={this.state.fromDate}
                            onChange={(value: any) =>
                              this.handleDateChange(value, "fromDate")
                            }
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <FontAwesomeIcon
                                style={{ color: GRAY }}
                                icon={faCalendar}
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        <InputGroup className="flex-nowrap mr-2 b-min-width-150">
                          <DatePicker
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            disabledKeyboardNavigation
                            selected={this.state.toDate}
                            onChange={(value: any) =>
                              this.handleDateChange(value, "toDate")
                            }
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <FontAwesomeIcon
                                style={{ color: GRAY }}
                                icon={faCalendar}
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </>
                    )}
                    {(this.state.reportSelection === "roomClosureByDate" ||
                      this.state.reportSelection === "revenuePerProductGroup" ||
                      this.state.reportSelection === "revenuePerLabel") && (
                      <Input
                        type="select"
                        name="selectedRoom"
                        id="selectedRoom"
                        className="mr-3"
                        onChange={this.handleSelection}
                      >
                        <option value="">{t("common.select")}</option>
                        {this.props.room?.map((r: any) => {
                          return (
                            <option value={r.uuid}>
                              {
                                commonService.applyLocalization(
                                  "restaurant",
                                  "name",
                                  r.locales
                                )["name"]
                              }
                            </option>
                          );
                        })}
                      </Input>
                    )}
                    {this.state.reportSelection === "onlineInventoryReport" && (
                      <Input
                        type="select"
                        name="selectedRestaurant"
                        id="selectedRestaurant"
                        className="mr-3"
                        onChange={this.handleSelection}
                      >
                        {this.withCurrentRestaurant(
                          this.supplierRestaurants
                        )?.map((r: any) => {
                          return (
                            <option value={r.uuid}>
                              {
                                commonService.applyLocalization(
                                  "restaurant",
                                  "name",
                                  r.locales
                                )["name"]
                              }
                            </option>
                          );
                        })}
                      </Input>
                    )}
                    {this.state.reportSelection === "requestsForTimeFrame" && (
                      <Input
                        type="select"
                        name="requestType"
                        id="requestType"
                        className="mr-3"
                        onChange={this.handleSelection}
                      >
                        <option value="">{t("reports.selectOrderType")}</option>
                        {ORDER_TYPE.map((r: any) => {
                          return <option value={r.value}>{t(r.label)}</option>;
                        })}
                      </Input>
                    )}
                    {this.state.reportSelection === "requestsForTimeFrame" &&
                      this.state.requestType === "2" && (
                        <Input
                          type="select"
                          name="clientRestaurant"
                          id="clientRestaurant"
                          className="mr-3"
                          onChange={this.handleSelection}
                        >
                          <option value="">{t("reports.selectClient")}</option>
                          {this.restaurantClients.map((r: any) => {
                            return (
                              <option value={r.uuid}>
                                {
                                  commonService.applyLocalization(
                                    "restaurant",
                                    "name",
                                    r.locales
                                  )["name"]
                                }
                              </option>
                            );
                          })}
                        </Input>
                      )}
                    {this.state.reportSelection ===
                      "supplierOrdersForTimeframe" && (
                      <Input
                        type="select"
                        name="selectedSupplier"
                        id="selectedSupplier"
                        className="mr-3"
                        onChange={this.handleSelection}
                      >
                        <option value="">---</option>
                        {this.supplierList.map((r: any) => {
                          return (
                            <option value={r.uuid}>
                              {
                                commonService.applyLocalization(
                                  "restaurant",
                                  "name",
                                  r.locales
                                )["name"]
                              }
                            </option>
                          );
                        })}
                      </Input>
                    )}
                    {this.state.reportSelection === "serviceSetReport" && (
                      <Input
                        type="select"
                        name="serviceSet"
                        id="serviceSet"
                        className="mr-3"
                        onChange={this.handleSelection}
                      >
                        <option value="">
                          {t("reports.selectServiceSet")}
                        </option>
                        {!this.props.isFetching &&
                          this.props?.serviceset?.map((ss: any) => {
                            return (
                              <option value={ss.uuid}>
                                {
                                  commonService.applyLocalization(
                                    "restaurant",
                                    "name",
                                    ss.locales
                                  )["name"]
                                }
                              </option>
                            );
                          })}
                      </Input>
                    )}
                    <Button
                      color="info"
                      onClick={this.runReport}
                      disabled={
                        (this.state.reportSelection === "roomClosureByDate" &&
                          this.state.selectedRoom === "") ||
                        (this.state.reportSelection === "serviceSetReport" &&
                          this.state.serviceSet === "") ||
                        this.state.fromDate.getTime() >
                          this.state.toDate.getTime()
                      }
                    >
                      {t("common.run")}
                    </Button>
                  </div>
                </header>
                <div className="col-12">
                  {this.renderReports(this.state.activeReport)}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.recipeprinter.isFetching ||
    state.reportOpenTransactionQlty.isFetching ||
    state.reportPaymentTransactionQlty.isFetching ||
    state.reportRestaurantClosure.isFetching ||
    state.room.isFetching ||
    state.reportRoomClosure.isFetching ||
    state.reportPrintGuestGroup.isFetching ||
    state.serviceset.isFetching;
  let failure =
    state.recipeprinter.failure ||
    state.reportOpenTransactionQlty.failure ||
    state.reportPaymentTransactionQlty.failure ||
    state.reportRestaurantClosure.failure ||
    state.room.failure ||
    state.reportRoomClosure.failure ||
    state.reportPrintGuestGroup.failure ||
    state.serviceset.failure;
  return {
    printers: state.recipeprinter.data,
    isFetching: isFetching,
    failure: failure,
    reportOpenTransactionQlty: state.reportOpenTransactionQlty.data,
    reportPaymentTransactionQlty: state.reportPaymentTransactionQlty.data,
    reportRestaurantClosure: state.reportRestaurantClosure.data.data,
    room: state.room.data,
    reportRoomClosure: state.reportRoomClosure.data.data,
    serviceset: state.serviceset.data,
  };
};
const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getOpenTransactionQlty: (credentials: any) => {
      dispatch(getOpenTransactionQltyRequest(credentials));
    },
    getPaymentTransactionQlty: (credentials: any) => {
      dispatch(getPaymentTransactionQltyRequest(credentials));
    },
    getPrinter: (credentials: any) => {
      dispatch(recipePrinterRequest(credentials));
    },
    getRestaurantClosure: (credentials: any) => {
      dispatch(getRestaurantClosureRequest(credentials));
    },
    getRoomClosure: (credentials: any) => {
      dispatch(getRoomClosureRequest(credentials));
    },
    getRooms: (credentials: any) => {
      dispatch(roomRequest(credentials));
    },
    printGuestGroup: (credentials: any) => {
      dispatch(printGuestGroupReportRequest(credentials));
    },
    getServiceSet: (credentials: any) => {
      dispatch(servicesetRequest(credentials));
    },
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportsPage))
);

import { unitModalPayload } from "./units.model";

export interface ItemChoiceModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface ItemChoiceState {
  activeStorageDetails: any;
  barcode: string;
  cardItemData: any;
  choiceDetailsItems: Array<any>;
  ingredientClassDetailsItems: Array<any>;
  choiceOptions: any;
  color: string;
  component_price: boolean;
  confirmationCompletion: boolean;
  currentPage: any;
  deliveryDate: any;
  orderComment: string;
  description: string;
  elementId: string;
  fetchData: boolean;
  filterParams: any;
  globalUnits: Array<any>;
  isAddItemModalOpen: boolean;
  isDeleted: boolean;
  isDeleteModalOpen: boolean;
  isEdit: boolean;
  isFetching: boolean;
  isFilterApplied: boolean;
  isManged: string;
  ingredientsCost: any;
  inheritTags: boolean;
  isCostUpdated: boolean;
  isSaveButtonDisabled: boolean;
  isStorageUpdated: boolean;
  isUnitModalOpen: boolean;
  itemDetails: any;
  itemId: string;
  items: Array<any>;
  itemsDetails: any;
  isUpdated: boolean;
  labelOptions: Array<any>;
  labels: Array<any>;
  name: string;
  newUnit: any;
  outputquantity: string;
  outputQuantity: string;
  outputunituuid: string;
  outputUnit: string;
  package_quantity?: string;
  package_unit_uuid?: string;
  posName: string;
  printLabel: boolean;
  printerName: string;
  recipe: any;
  recipeDetails: any;
  recipeServiceSets: any;
  recipeSubstituteArray: any;
  recipeStorages: any;
  rooms: Array<any>;
  selectedElement: string;
  selectedIndex: any;
  selectedItemId: string;
  selectedRecipe: any;
  selectedLabels: Array<any>;
  shelfLife: any;
  showCartModal: boolean;
  shoppingCartArray: Array<any>;
  showOrderModal: boolean;
  sku: string;
  supplierList: Array<any>;
  supplierUnits: Array<any>;
  storage: Array<any>;
  storageFormData: any;
  supplierId: string;
  supplierOptions: any;
  tags: Array<any>;
  toggleStorageModal: boolean;
  totalPages: number;
  totalCount: number;
  type: string;
  unitModalPayload: unitModalPayload;
  units: Array<any>;
  updateId?: number;
  warnings: any;
  replacementItem: string;
  importedItems: Object;
  importedFrom?: String;
  outputQuantityUpdateFormula?: "same" | "changed";
  supplierInventoryInfo: Array<any>;
}

export const ItemChoiceInitState: ItemChoiceState = {
  activeStorageDetails: {},
  barcode: "",
  cardItemData: {},
  choiceDetailsItems: [],
  ingredientClassDetailsItems: [],
  choiceOptions: [],
  component_price: false,
  confirmationCompletion: false,
  currentPage: 1,
  deliveryDate: "",
  orderComment: "",
  color: "#FFFFFF",
  description: "",
  elementId: "",
  fetchData: false,
  filterParams: {},
  globalUnits: [],
  isAddItemModalOpen: false,
  isDeleted: false,
  isDeleteModalOpen: false,
  isEdit: false,
  isFetching: false,
  isFilterApplied: false,
  isManged: "1",
  isUnitModalOpen: false,
  ingredientsCost: {},
  inheritTags: false,
  isCostUpdated: false,
  isSaveButtonDisabled: true,
  isStorageUpdated: false,
  itemDetails: [],
  itemId: "",
  items: [],
  itemsDetails: [],
  isUpdated: false,
  labelOptions: [],
  labels: [],
  name: "",
  newUnit: null,
  outputquantity: "",
  outputQuantity: "",
  outputunituuid: "",
  outputUnit: "",
  posName: "",
  printLabel: false,
  printerName: "",
  recipe: "",
  recipeDetails: {},
  recipeServiceSets: {},
  recipeSubstituteArray: [],
  recipeStorages: {},
  rooms: [],
  selectedItemId: "",
  selectedElement: "",
  selectedIndex: "",
  selectedRecipe: {},
  selectedLabels: [],
  shelfLife: "",
  showCartModal: false,
  shoppingCartArray: [],
  showOrderModal: false,
  sku: "",
  supplierList: [],
  supplierUnits: [],
  storage: [],
  storageFormData: {},
  supplierId: "",
  supplierOptions: [],
  tags: [],
  toggleStorageModal: false,
  totalPages: 0,
  totalCount: 0,
  type: "",
  unitModalPayload: {
    grossWeight: "",
    grossWeightUnitSelection: null,
    hasGrossWeight: true,
    name: "",
    symbol: "",
  },

  units: [],
  warnings: {
    recipe: null,
    outputQuantity: null,
  },
  replacementItem: "",
  importedItems: {},
  importedFrom: "",
  supplierInventoryInfo: [],
};

export interface ItemChoiceRowState {
  isOpen: boolean;
}
export const ItemChoiceRowInitState = {
  isOpen: false,
};

import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import "../../styles/units.scss";
import { useTranslation } from "react-i18next";
import { Editor } from "@tinymce/tinymce-react";

export default function ContentManagementAddEditModal(props: any) {
  const { t, i18n } = useTranslation();
  const [description, setDescription] = useState<any>("");
  const [name, setName] = useState("");
  const editorRef: any = useRef(null);
  useEffect(() => {
    setName(props.activeContent.name);
    setDescription(props.activeContent.description);
  }, [props.activeContent]);

  const handleChange = (element: any) => {
    setName(element.target.value);
  };

  const handleSave = () => {
    props.onSave({
      name: name,
      description,
      uuid: props.activeContent?.uuid || "",
    });
  };

  const onEditorChange = (value: any) => {
    setDescription(value);
  };

  return (
    <Modal isOpen={props.addModal} size={"lg"}>
      <ModalHeader toggle={props.toggle} className="modal-header">
        {props.isEdit
          ? t("contentManagement.editContent")
          : t("contentManagement.addContent")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="name">{t("common.name")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required
              name="name"
              autoComplete="name"
              placeholder={t("common.name")}
              value={name}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="percentage">{t("contentManagement.content")}</Label>
            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              apiKey={process.env.REACT_APP_TINY_MCE_KEY}
              initialValue={description}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code codesample fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist  | " +
                  " code codesample  | removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                codesample_languages: [
                  { text: "JavaScript", value: "javascript" },
                  { text: "HTML/XML", value: "markup" },
                ],
              }}
              onEditorChange={(newValue, editor) => {
                onEditorChange(newValue);
              }}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>
          {t("common.cancel")}
        </Button>
        <Button color="info" onClick={handleSave} disabled={name.trim() === ""}>
          {t("common.save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

import React, { Component } from "react";
import { Collapse, Col, Input, Row, Label } from "reactstrap";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { CardItemCardComponent, CardItemContainer } from "../card-item";
import { withCardItemSelectionState } from "../../cardItemSelectionManager";
import WarningComment, {
  getMandatoryWarning,
} from "../../warning-comment/warning-comment";
import Select from "react-select";
import _ from "lodash";
import {
  UnitsCardInitState,
  UnitsCardProps,
  UnitsCardState,
} from "../../../models/units.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenSquare, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import commonService from "../../../services/common.service";
import { generateRandomNumber } from "../../../helpers/commonHelper";

class UnitItemCard extends Component<UnitsCardProps, any> {
  state: UnitsCardState;
  isNewOptionAdded: boolean = false;
  constructor(props: UnitsCardProps) {
    super(props);
    this.state = UnitsCardInitState;
  }

  componentDidMount() {
    this._loadValues();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      !this.isNewOptionAdded &&
      !!_.size(this.props.tempUnit) &&
      JSON.stringify(prevProps.tempUnit) !== JSON.stringify(this.props.tempUnit)
    ) {
      this.isNewOptionAdded = true;
      const currentWarning = {
        fromUnit: getMandatoryWarning(this.props.tempUnit),
      };
      const warnings = { ...this.state.warnings, ...currentWarning };

      setTimeout(() => {
        this.isNewOptionAdded = false;
        this.setState({
          fromUnit: this.props.tempUnit,
          warnings,
        });
      }, 100);
    }
    if (
      prevProps.cardItemSelectionData.selectionState.isBeingEdited !==
      this.props?.cardItemSelectionData.selectionState.isBeingEdited
    ) {
      this._loadValues();
    }
  }

  private _loadValues = () => {
    // if (this.props.isNewCardItem) {
    // } else {
    this._setInitialValues();
    // }
  };

  private _setInitialValues() {
    const { quantity, conversionFactor } = this.calcConversionFactorFormData(
      this.props.cardItemData
    );
    const fromUnitData = this.props.allUnits?.find(
      (unit: any) => unit.uuid === this.props.cardItemData.uuid
    );
    const fromUnit = fromUnitData?.uuid
      ? {
          label: this.props.getUnitName(fromUnitData?.uuid),
          value: fromUnitData?.uuid,
        }
      : null;
    const toUnitData = this.props.allUnits?.find(
      (unit: any) => unit.uuid === this.props.cardItemData.isRelateTo
    );

    const toUnit = toUnitData?.uuid
      ? {
          label: this.props.getUnitName(toUnitData?.uuid),
          value: toUnitData?.uuid,
        }
      : null;

    const grossWeightUnit =
      this.props.getBaseUnitOptions?.find(
        (unit: any) => unit.value === this.props?.baseWeightUnit
      ) || [];
    this.setState({
      fromUnit,
      grossWeight: this.props.cardItemData?.grossWeight || "",
      grossWeightUnit,
      quantity,
      conversionFactor,
      toUnit,
      warnings: {
        conversionFactor: getMandatoryWarning(conversionFactor),
        fromUnit: getMandatoryWarning(fromUnit),
        toUnit: getMandatoryWarning(toUnit),
        quantity: getMandatoryWarning(quantity),
      },
      sku: this.props.cardItemData?.sku ?? "",
      barcode:
        this.props.cardItemData?.barcode ??
        (this.props?.cardItemData?.type === "newCard"
          ? this.props.restaurantCode + generateRandomNumber(10)
          : ""),
    });
  }

  calcConversionFactorFormData = (itemData: any) => {
    if (itemData?.type === "newCard") {
      return { quantity: 1, conversionFactor: 1 };
    }
    let conversionFactor = this.props.getConversionFactor(itemData);
    let quantity: number = Number(itemData?.quantity || 1);
    conversionFactor = conversionFactor * quantity;
    if (quantity <= conversionFactor) {
      conversionFactor = conversionFactor / quantity;
      quantity = 1;
    } else {
      quantity = quantity / conversionFactor;
      conversionFactor = 1;
    }
    return {
      quantity: Number(quantity.toFixed(3)),
      conversionFactor: Number(conversionFactor.toFixed(3)),
    };
  };

  deleteCard = () => {
    this.props.onDeleteCard(this.props.cardItemData.conversionUuid);
  };

  getConversionUnits = (unitData: any, allUnits: Array<any>) => {
    const options: Array<any> = [];
    if (unitData?.conversions?.length > 0) {
      unitData?.conversions?.forEach((unit: any) => {
        if (
          unit.isRelateTo === unitData.uuid &&
          unit.uuid !== this.props.cardItemData.uuid
        ) {
          const fromOption: any = {
            value: unit.uuid,
            label: this.props.getUnitName(unit.uuid, "unit"),
            conversionFactor: unit.conversionFactor,
          };
          options.push(fromOption);
        }
        // }
      });
    }
    return options;
  };

  getUnitOptions = (area: string) => {
    let options: Array<any> = [];

    if (area === "all") {
      this.props.allAnchorUnits
        .filter((unit: any) => {
          if (this.props.conversionsList.includes(unit.uuid)) return false;
          const found = unit.uuid !== this.props.unit.uuid;
          const isVisible =
            this.props?.type === "unit" ? !unit.recipe_uuid : true;

          return found && isVisible;
        })
        .map((unit: any) => {
          options.push({
            value: unit.uuid,
            label: this.props.getUnitName(unit.uuid, "unit"),
          });
        });
      options = _.sortBy(options, [
        (option: any) => option.label.toLowerCase(),
      ]);
      const newOption: any = {
        label: this.props.t("unit.newUnit"),
        value: "NEW_UNIT",
      };
      options.push(newOption);

      if (
        this.isNewOptionAdded &&
        !!_.size(this.props.tempUnit) &&
        options[options.length - 1].value !== this.props.tempUnit?.value
      ) {
        options.push(this.props.tempUnit);
      }
    } else if (area === "relatedUnit") {
      let unitData: any;
      // const allUnits: Array<any> = !!this.props.cardItemData?.isRelateTo
      //   ? this.props.allUnits.filter(
      //       (unit: any) => unit.uuid === this.props.cardItemData.isRelateTo
      //     )
      //   : this.props.allAnchorUnits.filter(
      //       (unit: any) => unit.uuid === this.props.unit.uuid
      //     );
      const allAnchorUnits: Array<any> = this.props.allAnchorUnits.filter(
        (unit: any) => unit.uuid === this.props.unit.uuid
      );
      allAnchorUnits.map((unit: any) => {
        unitData = unit;
        if (unit.uuid !== this.props.cardItemData.uuid) {
          options.push({
            value: unit.uuid,
            label: this.props.getUnitName(unit.uuid, "unit"),
            conversionFactor: 1,
          });
        }
      });
      const conversionOptions: any = this.getConversionUnits(unitData, options);
      options = _.sortBy(
        [...options, ...conversionOptions],
        [(option: any) => option.label.toLowerCase()]
      );
    }
    return options;
  };

  handleCancel = () => {
    this.props.onConversionCancel();
    this.props.cardItemSelectionData.deselectCardItem();
  };

  handleSelectChange = (value: any, area: string) => {
    if (value.value === "NEW_UNIT") {
      this.props.onNewUnitSelection();
      return;
    }
    const currentWarning = {
      [area]: getMandatoryWarning(value),
    };
    const warnings = { ...this.state.warnings, ...currentWarning };
    this.setState({
      warnings: warnings,
      [area]: value,
    });
  };

  handleChange = (elem: any) => {
    let currentWarning: any;

    currentWarning = {
      [elem.target.name]: getMandatoryWarning(elem.target.value),
    };
    const warnings = { ...this.state.warnings, ...currentWarning };

    this.setState({
      [elem.target.name]: elem.target.value,
      warnings: warnings,
    });
  };

  handleUnitUnitEdit = () => {
    const formData: any = {
      locales: this.props.cardItemData.locales,
      uuid: this.props.cardItemData.uuid,
      gross_weight: !isNaN(this.props.cardItemData.gross_weight)
        ? String(this.props.cardItemData.gross_weight)
        : this.props.cardItemData.gross_weight,
    };
    this.props.handleUnitUnitEdit(formData);
  };

  saveCard = () => {
    const quantity = Number(this.state?.quantity || 1);
    const isValidFromUnit: boolean = this.props.cardItemSelectionData.cardItem
      .isNewCardItem
      ? this.state.warnings.fromUnit === null
      : true;
    if (
      isValidFromUnit &&
      this.state.warnings.toUnit === null &&
      this.state.warnings.conversionFactor === null &&
      this.state.warnings.quantity === null
    ) {
      const conversionFactor = this.state.toUnit?.conversionFactor || 1;
      const toUnit: string = !!this.props.cardItemData?.isRelateTo
        ? this.props.cardItemData.isRelateTo
        : this.props.unit.uuid;
      const formData: any = {
        quantity: quantity,
        conversion_factor:
          (parseFloat(this.state.conversionFactor) * conversionFactor) /
          quantity,
        from_unit: this.state.fromUnit?.value,
        to_unit: toUnit,
        sku: this.state.sku,
        barcode: this.state.barcode,
      };

      if (!this.props.isNewCardItem) {
        formData.uuid = this.props.cardItemData.conversionUuid;
      }
      if (this.props.area === "recipe" && this.state.grossWeight !== "") {
        formData.grossWeight = this.state.grossWeight;
        formData.grossWeightUnit = this.state.grossWeightUnit;
      }

      this.props.onSaveCard(formData);
      if (this.props.isNewCardItem) {
        this._setInitialValues();
      }
    }
  };

  selectCard = () => {
    if (this.props.cardItemSelectionData.selectionState.mode === "edit") {
      this._loadValues();
    }

    const { cardItemData, unit } = this.props;
    const isCardDisabled: boolean =
      (cardItemData.type !== "newCard" &&
        this.props.area === "recipe" &&
        cardItemData?.isRelateTo !== unit.uuid) ||
      (this.props.isParentGlobalUnit && !cardItemData?.recipe_uuid);

    if (!isCardDisabled) this.props.cardItemSelectionData.selectCardItem();
  };

  randomString(length: number, chars: string) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  setSKU = () => {
    this.setState({
      sku: this.randomString(
        12,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      ),
    });
  };

  render() {
    const { t, cardItemData, unit } = this.props;
    const isCardDisabled: boolean =
      (cardItemData.type !== "newCard" &&
        this.props.area === "recipe" &&
        cardItemData?.isRelateTo !== unit.uuid) ||
      (this.props.isParentGlobalUnit && !cardItemData?.recipe_uuid);
    const { quantity, conversionFactor } =
      this.calcConversionFactorFormData(cardItemData);
    const conversionFactorRounded: number =
      isNaN(conversionFactor) || conversionFactor === ""
        ? 0
        : Number(conversionFactor.toFixed(3));

    const isUnitEditable: boolean =
      this.props.area === "recipe"
        ? this.props.cardItemData.isRecipeUnit
        : true;
    return (
      <CardItemContainer className={"task-type px-0 "}>
        <CardItemCardComponent
          cardItemSelectionData={this.props.cardItemSelectionData}
          cancelCallback={this.handleCancel}
          className={`task-type assigned ${isCardDisabled ? "disabled" : ""}`}
          deleteCallback={this.deleteCard}
          onDeleteClick={this.deleteCard}
          readOnly={isCardDisabled}
          saveCallback={this.saveCard}
          selectCallback={this.selectCard}
          saveButton={this.props.saveButton}
        >
          <Row>
            {!this.props.cardItemSelectionData.selectionState
              .isItemSelected && (
              <>
                <Col lg={this.props.area === "recipe" ? 4 : 5}>
                  {quantity}{" "}
                  {this.props.getUnitName(cardItemData.uuid, "fullUnit")}
                </Col>
                <Col lg={this.props.area === "recipe" ? 5 : 6}>
                  {" "}
                  {t("unit.correspondsTo")} {conversionFactorRounded}{" "}
                  {this.props.getUnitName(cardItemData.isRelateTo, "fullUnit")}
                </Col>

                {this.props.area === "recipe" && (
                  <Col lg={3}>
                    {cardItemData?.grossWeight ? (
                      <span>
                        {cardItemData.grossWeight}{" "}
                        {this.props.getUnitName(
                          this.props?.baseWeightUnit,
                          "symbol"
                        )}
                      </span>
                    ) : (
                      <span className="text-warning">
                        {!isCardDisabled && t("unit.noGrossWeightsAdded")}
                      </span>
                    )}
                  </Col>
                )}
              </>
            )}
            {this.props.cardItemSelectionData.selectionState.isItemSelected && (
              <>
                <Col lg={4}>
                  <Row className="p-0">
                    <Col md="6">
                      {this.props.cardItemSelectionData.selectionState
                        .isBeingEdited ? (
                        <WarningComment
                          data={this.state.warnings.quantity}
                          displayType={"bottom"}
                          validationStyle={"iconOnly"}
                        >
                          <Input
                            type="number"
                            name="quantity"
                            id="quantity"
                            placeholder={t("common.enterQuantity")}
                            onChange={this.handleChange}
                            value={this.state.quantity}
                          />
                        </WarningComment>
                      ) : (
                        <span>{quantity}</span>
                      )}
                    </Col>

                    {this.props.cardItemSelectionData.selectionState
                      .isBeingEdited && this.props.isNewCardItem ? (
                      <Col md="6">
                        <WarningComment
                          data={this.state.warnings.fromUnit}
                          displayType={"bottom"}
                          validationStyle={"iconOnly"}
                        >
                          <div className="d-flex align-items-center">
                            <Select
                              name={"fromUnit"}
                              value={this.state.fromUnit}
                              onChange={(val) =>
                                this.handleSelectChange(val, "fromUnit")
                              }
                              options={this.getUnitOptions("all")}
                              maxMenuHeight={200}
                              className=" flex-fill"
                              styles={{
                                menu: (provided: any) => ({
                                  ...provided,
                                  color: "#000",
                                }),
                              }}
                            />
                          </div>
                        </WarningComment>
                      </Col>
                    ) : (
                      <Col md="6">
                        <span className=" ">
                          {this.props.getUnitName(
                            cardItemData.uuid,
                            "fullUnit"
                          )}
                          {isUnitEditable && (
                            <FontAwesomeIcon
                              onClick={() => this.handleUnitUnitEdit()}
                              icon={faPenSquare}
                              className={"b-icon mx-2 b-cursor-p"}
                              title={t("common.edit")}
                            />
                          )}
                        </span>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col lg={8} className="d-flex mr-auto">
                  <span className="mr-2">{t("unit.correspondsTo")} </span>
                  {!this.props.cardItemSelectionData.selectionState
                    .isBeingEdited ? (
                    <div className="d-flex justify-content-between  flex-fill">
                      <div className="mr-3 col pl-0">
                        {conversionFactorRounded}{" "}
                        {this.props.getUnitName(
                          cardItemData.isRelateTo,
                          "fullUnit"
                        )}
                      </div>
                      {this.props.area === "recipe" && (
                        <div className="col-lg-6">
                          {cardItemData?.grossWeight ? (
                            <span>
                              {cardItemData.grossWeight}{" "}
                              {this.props.getUnitName(
                                this.props?.baseWeightUnit,
                                "symbol"
                              )}
                            </span>
                          ) : (
                            <div className="text-warning ">
                              {!isCardDisabled && t("unit.noGrossWeightsAdded")}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="d-flex flex-fill ">
                      <div className="d-flex b-width-150 mr-2">
                        <WarningComment
                          data={this.state.warnings.conversionFactor}
                          displayType={"bottom"}
                          validationStyle={"iconOnly"}
                        >
                          <Input
                            type="number"
                            name="conversionFactor"
                            id="conversionFactor"
                            placeholder={t("common.enterQuantity")}
                            onChange={this.handleChange}
                            value={this.state.conversionFactor}
                          />
                        </WarningComment>
                      </div>
                      <div className="b-min-width-150 flex-fill">
                        <WarningComment
                          data={this.state.warnings.toUnit}
                          displayType={"bottom"}
                          validationStyle={"iconOnly"}
                        >
                          {" "}
                          <Select
                            name={"toUnit"}
                            value={this.state.toUnit}
                            onChange={(val) =>
                              this.handleSelectChange(val, "toUnit")
                            }
                            options={this.getUnitOptions("relatedUnit")}
                            maxMenuHeight={200}
                            styles={{
                              menu: (provided: any) => ({
                                ...provided,
                                color: "#000",
                              }),
                            }}
                          />
                        </WarningComment>
                      </div>
                    </div>
                  )}
                </Col>
              </>
            )}
          </Row>
          {this.props.area === "recipe" &&
            this.props.cardItemSelectionData.selectionState.isBeingEdited && (
              <Row>
                <Col lg={5} md={5}>
                  <div>
                    <div>
                      <label className="mr-3">{t("unit.grossWeight")} </label>
                    </div>
                    <div className="d-flex flex-fill">
                      <div className="b-width-100 mr-3">
                        <Input
                          type="number"
                          name="grossWeight"
                          id="grossWeight"
                          onChange={this.handleChange}
                          value={this.state.grossWeight}
                        />
                      </div>

                      <Select
                        name={"grossWeightUnit"}
                        value={this.state.grossWeightUnit}
                        onChange={(val) =>
                          this.handleSelectChange(val, "grossWeightUnit")
                        }
                        options={
                          !!this.props?.getBaseUnitOptions
                            ? this.props.getBaseUnitOptions
                            : []
                        }
                        maxMenuHeight={200}
                        className=" flex-fill"
                        styles={{
                          menu: (provided: any) => ({
                            ...provided,
                            color: "#000",
                          }),
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={3} md={3}>
                  <div>
                    <div>
                      <label className="mr-3">{t("common.sku")} </label>
                    </div>
                    <div className="d-flex flex-fill">
                      <Input
                        style={{ flex: 0.95 }}
                        type="text"
                        required
                        name="sku"
                        autoComplete="sku"
                        placeholder={t("common.enterSKU")}
                        value={this.state.sku}
                        onChange={this.handleChange}
                      />
                      <span
                        style={{ flex: 0.1, cursor: "pointer" }}
                        onClick={this.setSKU}
                      >
                        <FontAwesomeIcon
                          style={{
                            color: "#3f3f3f",
                            fontSize: 20,
                            marginLeft: 10,
                            marginTop: 8,
                          }}
                          icon={faPlusCircle}
                        />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4}>
                  <div>
                    <div>
                      <label className="mr-3">
                        {t("common.productBarcode")}{" "}
                      </label>
                    </div>
                    <div className="d-flex flex-fill">
                      <Input
                        type="text"
                        required
                        name="barcode"
                        autoComplete="barcode"
                        placeholder={t("common.enterProductBarcode")}
                        value={this.state.barcode}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            )}
        </CardItemCardComponent>
      </CardItemContainer>
    );
  }
}

export default withTranslation()(
  withRouter(withCardItemSelectionState("UnitItemCard", 1)(UnitItemCard))
);

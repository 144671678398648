import "../../styles/page404.scss";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Alert, Button, Col, Table } from "reactstrap";
import { ORDER_STATUS, ORDER_TYPE, WHITE } from "../../constant/constant";

import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import commonService from "../../services/common.service";
class ListOfRequests extends Component<any> {
  render() {
    const activeRestaurant = JSON.parse(
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
    );
    const { t, data } = this.props;
    let totalCost = 0;
    return (
      <>
        {data?.length === 0 && (
          <Alert color="info">{t("common.noRecords")}</Alert>
        )}
        {data?.length > 0 && (
          <>
            <h5>{t("reports.requestsForTimeFrame")}</h5>
            {data?.map((record: any, index: any) => {
              const orderStatus = ORDER_STATUS.find(
                (s) => s.value === record.status
              );
              const orderType = ORDER_TYPE.find((t) => t.value === record.type);
              const title = orderType
                ? orderType.value === 2
                  ? record.supplierRestaurantName
                  : orderType.value === 0
                  ? record.tableName + " / " + record.courseName
                  : t(orderType.label)
                : null;
              return (
                <section className="mt-4">
                  {" "}
                  <div className="d-flex  align-items-center justify-content-between">
                    <h6 className=" mb-2">
                      {title && title + " - "}
                      {record.uuid.slice(-8)}
                    </h6>
                    {orderStatus &&
                    orderType &&
                    orderStatus.value === 1 &&
                    (orderType.value === 0 || orderType.value === 2) ? (
                      <Button className=" mb-2" color="info" size="sm">
                        {t("reports.printShippingNote") + " "}
                        <FontAwesomeIcon
                          style={{ color: WHITE }}
                          icon={faPrint}
                        />
                      </Button>
                    ) : null}
                  </div>
                  <Table bordered striped>
                    <thead>
                      <tr className="bg-light">
                        <th className="w-19">
                          {t("reports.expectedDeliveryTime")}
                        </th>
                        <th className="w-16">{t("common.status")}</th>
                        <th className="w-20">{t("common.type")}</th>
                        <th className="w-28">
                          {t("reports.requestItems")}
                          {"("}
                          {t("reports.orderQuantity")}
                          {" x "}
                          {t("reports.outputQunatityUnit")}
                          {")"}
                        </th>
                        <th className="w-8">{t("common.price")}</th>
                        <th className="w-9">{t("common.totalPrice")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <td>{record.expectedDeliveryTime}</td>
                      <td>{orderStatus && t(orderStatus.label)}</td>
                      <td>{orderType && t(orderType.label)}</td>
                      <td>
                        <ul>
                          {record?.recipes?.map((data: any) => {
                            return (
                              <li>
                                {data.recipeName} {"("}
                                {data.quantity &&
                                  commonService.getSignificantDigit(
                                    data.quantity
                                  )}
                                {" x "}
                                {data.outputQuantity} {data.outputUnitName}
                                {")"}
                              </li>
                            );
                          })}
                        </ul>
                      </td>
                      <td>
                        {record?.recipes?.map((data: any) => {
                          return (
                            <div>
                              {activeRestaurant?.currency_code}{" "}
                              {parseFloat(
                                commonService.displayDigitalAmount(data.price)
                              )?.toFixed(activeRestaurant?.minor_units)}
                            </div>
                          );
                        })}
                      </td>
                      <td>
                        {record?.recipes?.map((data: any) => {
                          let cost = parseFloat(
                            commonService.displayDigitalAmount(data.totalCost)
                          )?.toFixed(activeRestaurant?.minor_units);
                          totalCost += parseFloat(cost);
                          return (
                            <div>
                              {activeRestaurant?.currency_code} {cost}
                            </div>
                          );
                        })}
                      </td>
                    </tbody>
                  </Table>
                </section>
              );
            })}
            <Col className="col-lg-12 mb-2 pr-0">
              <div style={{ textAlign: "right" }}>
                <strong>
                  {t("reports.totalTurnoverForDefinedFilter")}:{" "}
                  {activeRestaurant?.currency_code} {totalCost}
                </strong>
              </div>
            </Col>
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withRouter(ListOfRequests));

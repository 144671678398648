import "../../styles/page404.scss";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Alert, Table } from "reactstrap";
import { withTranslation } from "react-i18next";
import commonService from "../../services/common.service";
class supplierOrders extends Component<any> {
  activeRestaurant = JSON.parse(
    localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
  );

  totalCost = () => {
    const total = this.props.data?.reduce(
      (tCost: number, r: any) => tCost + r.itemPrice,
      0
    );
    return parseFloat(commonService.displayDigitalAmount(total))?.toFixed(
      this.activeRestaurant?.minor_units
    );
  };

  render() {
    const { t, data } = this.props;
    return (
      <>
        {data?.length === 0 && (
          <Alert color="info">{t("common.noRecords")}</Alert>
        )}

        {data?.length > 0 && (
          <div className="d-flex justify-content-between">
            <h5>{t("reports.supplierOrdersForTimeframe")}</h5>
          </div>
        )}

        {data?.length > 0 && (
          <section className="mt-3">
            <div
              style={{
                textAlign: "right",
              }}
            >
              <b>
                {t("reports.totalCostForTheDefinedTimeframe")}:{" "}
                {this.activeRestaurant?.currency_code} {this.totalCost()}
              </b>
            </div>
            <Table bordered striped>
              <thead>
                <tr className="bg-light">
                  <th className="w-18">{t("reports.expectedDeliveryTime")}</th>
                  <th className="w-20">{t("reports.supplierName")}</th>
                  <th className="w-22">{t("reports.itemName")}</th>
                  <th className="w-13">{t("reports.orderQuantity")}</th>
                  <th className="w-13">{t("reports.outputQunatityUnit")}</th>
                  <th className="w-14">{t("common.totalPrice")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((r: any) => {
                  let cost = parseFloat(
                    commonService.displayDigitalAmount(r.itemPrice)
                  )?.toFixed(this.activeRestaurant?.minor_units);
                  return (
                    <tr>
                      <td>{r.expectedDeliveryTime}</td>
                      <td>{r.supplierName}</td>
                      <td>{r.itemName}</td>
                      <td>
                        {r.orderQuantity &&
                          commonService.getSignificantDigit(r.orderQuantity)}
                      </td>
                      <td>
                        {r.outputQuantity} {r.outputUnit}
                      </td>
                      <td>
                        {this.activeRestaurant?.currency_code} {cost}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </section>
        )}
      </>
    );
  }
}
export default withTranslation()(withRouter(supplierOrders));

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  servicesetAdd,
  servicesetRemove,
  servicesetRequest,
  servicesetUpdate,
} from "../../redux/actions/serviceset.action";
import ConceptHeaderComponent from "../../components/navigation/navigation-header/concept-header/concept-header";
import ModalFactory from "react-modal-promise";
import RestaurantNavComponent from "../../components/navigation/navigation-left/restaurant-navigation/restaurant-navigation";
import commonService from "../../services/common.service";
import LoaderComponent from "../../components/loader/loader";
import { Col, Row } from "reactstrap";
import {
  restaurantMetaInfo,
  ServiceSetOverviewInitState,
  serviceSetOverviewMode,
  ServiceSetOverviewState,
} from "../../models/serviceset.model";
import ServiceSetAddModal from "../../components/service-set/serviceSetAddModal";
import ServiceSetOverviewListWrapper from "../../components/service-set/ServiceSetOverviewListWrapper";
import ServiceSetOverviewItemListWrapper from "../../components/service-set/ServiceSetOverviewItemListWrapper";
import { recipeItemsRequest } from "../../redux/actions/recipe.action";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import servicesetDetailsService from "../../services/serviceset.details.service";
import handleResponse from "../../services/response.service";
import _ from "lodash";
import { getIngredientCost } from "../../services/ingredients.service";
import {
  copyServiceSet,
  updateServiceSetRecipe,
} from "../../services/service.set";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { vatRequest } from "../../redux/actions/vat.action";
import { OpenDeleteModal } from "../../components/card-components/delete-card/delete-modal";
import {
  getRecipeStorageDetails,
  updateRecipeStorage,
} from "../../services/recipe.service";
import { getRestaurantItemMetaDetails } from "../../services/restaurant.detail.service";
import { INVENTORY_TYPE } from "../../constant/constant";
import ManageStorageStorage from "../../components/manage-storage/manage-storage";
import { unitsRequest } from "../../redux/actions/units.action";
import { storageType } from "../../models/storage.model";
import { getRestaurantSuppliers } from "../../services/supplier.restaurants.service";

class ServiceSetPageOverviewPage extends Component<any> {
  activeServiceSetData: any = {};
  state: ServiceSetOverviewState;
  recipeLocationsData: any = {};
  restaurantMetaData: restaurantMetaInfo = {
    labels: [],
    locations: {},
    rooms: [],
    storages: [],
  };
  recipeServiceSetsData: any = {};
  restaurantUuid: string = this.props.match.params.uuid;
  serviceSetRecipeData: any = {};
  isRecipeEdit: boolean = false;
  isServiceSetEdit: boolean = false;
  pageMode: serviceSetOverviewMode = serviceSetOverviewMode.NONE;
  constructor(props: any) {
    super(props);
    this.state = ServiceSetOverviewInitState;
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.dispatchActions.getAllRecipes({
      uuid: this.props.match.params.uuid,
      type: "1,2,3,4,5",
    });
    this.getSuppliers();
    this.getServiceSetList();

    this.loadMetaData();
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.isUpdated && !this.props.storeProps.isFetching) {
      this.setState({ isUpdated: false, isFetching: false });
      this.getServiceSetList();
    }
  }

  addNewStorage = (ingredientId: string, area: string, data: any) => {
    const mainStorageDetails = this.recipeLocationsData[ingredientId]?.find(
      (item: any) => item.main_storage
    );
    this.setState({
      activeStorageDetails: {
        area,
        data,
        ingredientId,
      },

      storageFormData: {
        inventory_type:
          mainStorageDetails?.inventory_type || INVENTORY_TYPE.MANAGED,
        main_storage: false,
        min_quantity: "",
        max_quantity: "",
        output_quantity: "",
        output_unit: "",
      },
      isStorageModalOpen: true,
    });
  };

  editStorage = (ingredientId: string, uuid: string) => {
    let allRecipeStorages = _.clone(this.recipeLocationsData[ingredientId]);
    const editData = allRecipeStorages.find((st: any) => st.uuid === uuid);
    const recipe = this.props.storeProps.allRecipes?.find(
      (item: any) => item.uuid === ingredientId
    );
    let quantityUpdated: any = editData?.output_quantity;
    let minQuantity: any = editData?.min_quantity;
    let maxQuantity: any = editData?.max_quantity;
    const currentItemOutputUnit: string = recipe?.output_unit_uuid || "";

    if (currentItemOutputUnit !== "") {
      const relatedUnits: Array<any> = this.getRelatedUnits(
        currentItemOutputUnit,
        ingredientId
      );
      const savedUnitDetails: any = relatedUnits.find(
        (u: any) => u.value === editData?.output_unit
      );
      if (savedUnitDetails) {
        quantityUpdated = commonService.getSignificantDigit(
          Number(quantityUpdated) / savedUnitDetails.conversionFactor
        );
        minQuantity = commonService.getSignificantDigit(
          Number(minQuantity) / savedUnitDetails.conversionFactor
        );
        maxQuantity = commonService.getSignificantDigit(
          Number(maxQuantity) / savedUnitDetails.conversionFactor
        );
      }
    }
    this.setState({
      activeStorageDetails: {
        isEdit: true,
        editData,
        ingredientId,
      },
      storageFormData: {
        inventory_type: editData?.inventory_type,
        main_storage: editData?.main_storage,
        min_quantity: minQuantity,
        max_quantity: maxQuantity,
        output_quantity: quantityUpdated,
        output_unit: editData?.output_unit,
      },
      isStorageModalOpen: true,
    });
  };
  getAddModalLabel = () => {
    return {
      modalTitle: this.state.isEdit
        ? this.props.t("serviceSet.editServiceSet")
        : this.props.t("serviceSet.addServiceSet"),
    };
  };

  getBestMatchUnits = (quantity: string, unitId: string, recipeId: string) => {
    if (quantity) {
      let quantityUpdated: any = quantity;
      const recipeDetails: any = this.props.storeProps.allRecipes.find(
        (recipe: any) => recipe.uuid === recipeId
      );

      const currentItemOutputUnit: string = recipeDetails?.output_unit_uuid;
      const relatedUnits: Array<any> = this.getRelatedUnits(
        currentItemOutputUnit,
        recipeId
      );
      const savedUnitDetails: any = relatedUnits.find(
        (u: any) => u.value === unitId
      );
      if (savedUnitDetails) {
        quantityUpdated = Math.round(
          Number(quantity) / savedUnitDetails.conversionFactor
        );

        quantityUpdated = commonService.getSignificantDigit(quantityUpdated);
      }
      const currentUnit = this.props.storeProps.units?.find(
        (unitData: any) => unitData.uuid === unitId
      );
      const data: any = {
        quantity: quantityUpdated,
      };

      if (currentUnit) {
        const locales: any = commonService.applyLocalization(
          "restaurant",
          "name",
          currentUnit?.locales
        );
        data["unit"] = {
          label: locales.name,
          symbol: locales.symbol,
          value: currentUnit.uuid,
        };
      }

      return data;
    }

    return;
  };

  getRecipeDetails = (recipeId: string) => {
    this.showLoader();
    const payload: any = {
      credentials: {
        restaurantuuid: this.restaurantUuid,
        uuid: recipeId,
      },
    };
    getIngredientCost(RESTAURANT_REQUEST, payload)
      .then((response: any) => {
        const responseData: any = handleResponse(response);
        if (responseData.ok) {
          this.recipeServiceSetsData[recipeId] = responseData.data.serviceSets;

          const serviceSets: Array<any> = responseData.data.serviceSets.map(
            (serviceSet: any) => serviceSet.service_set_uuid
          );
          const currentState: any = { ...this.state.recipeServiceSetsData };
          currentState[recipeId] = serviceSets;
          this.setState({
            activeServiceSet: "",
            activeRecipe: recipeId,
            recipeServiceSetsData: currentState,
            pageMode: serviceSetOverviewMode.RECIPE_SELECTION,
          });
          this.pageMode = serviceSetOverviewMode.RECIPE_SELECTION;
        }

        this.showLoader(false);
      })
      .catch((error: any) => this.showLoader(false));
  };

  getRecipeLocations = (recipeId: string) => {
    this.showLoader();
    const payload: any = {
      credentials: {
        restaurantuuid: this.restaurantUuid,
        recipeuuid: recipeId,
      },
    };
    getRecipeStorageDetails(RESTAURANT_REQUEST, payload)
      .then((response: any) => {
        const responseData: any = handleResponse(response);
        if (responseData.ok) {
          this.recipeLocationsData[recipeId] = responseData.data;
          const selectedStorages: Array<any> = [];
          responseData.data.forEach((storage: any) => {
            const roomDetails: any = this.restaurantMetaData.rooms.find(
              (str: any) => str.uuid === storage.room
            );
            const storageDetails: any = this.restaurantMetaData.storages.find(
              (str: any) => str.uuid === storage.storage
            );

            if (roomDetails) {
              const extendedData: any = {
                roomDetails,
                room_uuid: roomDetails.uuid,
                selectedStorage: storage,
                storageDetails,
                storage_uuid: storageDetails?.uuid,
              };
              selectedStorages.push(extendedData);
            }
          });
        }

        this.showLoader(false);
      })
      .catch((error: any) => this.showLoader(false));
  };

  getRelatedUnits = (unitId: string, recipeId: string) => {
    if (unitId) {
      const units: Array<any> =
        commonService.getRelateUnitDropdowns(
          unitId,
          this.props.storeProps.units,
          this.props.storeProps.units,
          1,
          recipeId
        ) || [];
      return units;
    }
    return [];
  };

  getRelatedUnitsForStorage = () => {
    const recipeDetails: any = this.props.storeProps.allRecipes.find(
      (recipe: any) =>
        recipe.uuid === this.state.activeStorageDetails.ingredientId
    );
    if (recipeDetails?.output_unit_uuid) {
      return this.getRelatedUnits(
        recipeDetails?.output_unit_uuid,
        recipeDetails?.uuid
      );
    }
    return [];
  };

  getServiceSetDetails = (serviceSetId: string) => {
    const minorUnit = JSON.parse(
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
    )?.minor_units;
    this.showLoader();
    const payload: any = {
      credentials: {
        restaurantUuid: this.restaurantUuid,
        itemUuid: serviceSetId,
      },
    };

    servicesetDetailsService
      .serviceSetDetails(RESTAURANT_REQUEST, payload)
      .then((response: any) => {
        const responseData: any = handleResponse(response);
        if (responseData.ok) {
          this.serviceSetRecipeData[serviceSetId] = responseData.data.recipes;
          const recipes: Array<any> = responseData.data.recipes.reduce(
            (items: Array<string>, recipe: any) => {
              if (!items.includes(recipe.recipe_uuid)) {
                items.push(recipe.recipe_uuid);
              }
              return items;
            },
            []
          );
          const currentState: any = { ...this.state.serviceSetRecipesData };
          currentState[serviceSetId] = recipes;
          const serviceSetRecipes: Array<any> = responseData.data.recipes.map(
            (r: any) => {
              r.price =
                (r.price / Math.pow(10, minorUnit))?.toFixed(minorUnit) || 0;
              return r;
            }
          );
          this.setState({
            activeRecipe: "",
            activeServiceSet: serviceSetId,
            serviceSetRecipesData: currentState,
            pageMode: serviceSetOverviewMode.SERVICE_SET_SELECTION,
            serviceSetRecipes: serviceSetRecipes,
            serviceSetRecipesClone: [...serviceSetRecipes],
          });
          this.pageMode = serviceSetOverviewMode.SERVICE_SET_SELECTION;
        }

        this.showLoader(false);
      })
      .catch((error: any) => this.showLoader(false));
  };

  getServiceSetList = () => {
    this.props.dispatchActions.getServiceSet({
      restaurantuuid: this.props.match.params.uuid,
    });
  };

  getSupplierDetails = (supplierId: string) => {
    const supplierDetails = this.state.supplierList.find(
      (supplier: any) => supplier.uuid === supplierId
    );
    return commonService.applyLocalization(
      "restaurant",
      "name",
      supplierDetails?.locales
    )["name"];
  };

  getSuppliers = () => {
    const recipeId: string = this.props.match.params.recipeuuid;
    const restaurantId: string = this.props.match.params.uuid;
    const payload = {
      credentials: {
        recipeuuid: recipeId,
        restaurantuuid: restaurantId,
      },
    };
    getRestaurantSuppliers(RESTAURANT_REQUEST, payload).then((data) => {
      const responseData: any = handleResponse(data);
      if (responseData?.ok) {
        this.setState({
          supplierList: responseData.data,
        });
      }
    });
  };

  handleRecipeEdit = (recipeId: string) => {
    this.pageMode = serviceSetOverviewMode.RECIPE_EDIT;
    this.activeServiceSetData["serviceSets"] = _.cloneDeep(
      this.state.recipeServiceSetsData[recipeId]
    );
    this.setState({
      activeRecipe: recipeId,
      pageMode: serviceSetOverviewMode.RECIPE_EDIT,
    });
  };

  handleRecipeEditCancel = (recipeId: string) => {
    const recipeServiceSetsData: any = { ...this.state.recipeServiceSetsData };
    recipeServiceSetsData[recipeId] = this.activeServiceSetData["serviceSets"];
    this.setState({
      pageMode: serviceSetOverviewMode.RECIPE_SELECTION,
      recipeServiceSetsData: recipeServiceSetsData,
    });
    this.pageMode = serviceSetOverviewMode.RECIPE_SELECTION;
    this.activeServiceSetData["serviceSets"] = {};
  };

  handleRecipeEditSave = (recipeId: string) => {
    this.showLoader();
    const recipeServiceList: Array<any> = [];
    const recipeServiceListOld: Array<any> =
      this.recipeServiceSetsData[recipeId];
    this.state.recipeServiceSetsData[recipeId]?.forEach((serviceSet: any) => {
      const recipeDetails: any = recipeServiceListOld.find(
        (ser: any) => ser.service_set_uuid === serviceSet
      );
      let serviceSetData: any = {};
      if (recipeDetails) {
        serviceSetData = {
          appear_on_bill: recipeDetails?.appear_on_bill,
          price: recipeDetails?.price,
          selectable: !!recipeDetails?.price ? true : false,
          service_set_uuid: serviceSet,
          status: recipeDetails?.status,
          uuid: recipeDetails?.uuid,
          default_course: recipeDetails?.default_course,
          vat_class_uuid: recipeDetails?.vat_class_uuid,
          offered_unit: recipeDetails?.offered_unit,
        };
      } else {
        serviceSetData = {
          appear_on_bill: true,
          price: 0,
          selectable: true,
          service_set_uuid: serviceSet,
          status: 1,
          offered_unit: null,
        };
      }
      recipeServiceList.push(serviceSetData);
    });
    updateServiceSetRecipe(RESTAURANT_REQUEST, {
      credentials: {
        restaurantuuid: this.props.match.params.uuid,
        uuid: recipeId,
        data: { service_sets: recipeServiceList },
      },
    })
      .then((response: any) => {
        const responseData: any = handleResponse(response);
        if (responseData.ok) {
          this.recipeServiceSetsData[recipeId] = undefined;
          const currentState: any = { ...this.state.recipeServiceSetsData };
          currentState[recipeId] = undefined;
          this.getRecipeDetails(recipeId);
        }

        this.showLoader(false);
      })
      .catch((error: any) => this.showLoader(false));
  };

  handleRecipeSelection = (recipeId: string) => {
    if (this.pageMode === serviceSetOverviewMode.SERVICE_SET_EDIT) {
      this.handleServiceSetRecipeSelection(recipeId);
    } else if (this.pageMode === serviceSetOverviewMode.SERVICE_SET_SELECTION) {
      this.handleServiceSetSelection(this.state.activeServiceSet);
    } else if (
      this.state.activeRecipe !== recipeId &&
      this.pageMode === serviceSetOverviewMode.RECIPE_EDIT
    ) {
      return;
    } else if (
      this.pageMode === serviceSetOverviewMode.NONE ||
      this.pageMode === serviceSetOverviewMode.RECIPE_SELECTION
    ) {
      if (this.state.activeRecipe === recipeId) {
        this.setState({
          activeRecipe: "",
          pageMode: serviceSetOverviewMode.NONE,
        });
        this.pageMode = serviceSetOverviewMode.NONE;
        return;
      }
      if (!this.recipeLocationsData[recipeId])
        this.getRecipeLocations(recipeId);
      if (!this.recipeServiceSetsData[recipeId])
        this.getRecipeDetails(recipeId);
      else {
        this.setState({
          activeServiceSet: "",
          activeRecipe: recipeId,
          pageMode: serviceSetOverviewMode.RECIPE_SELECTION,
        });

        this.pageMode = serviceSetOverviewMode.RECIPE_SELECTION;
      }
    }
  };

  setServiceSetRecipes = (data: Array<any>) => {
    this.setState({
      serviceSetRecipes: data,
    });
  };

  handleRecipeServiceSetSelections = (serviceSetId: string) => {
    const currentSelectedServiceSet =
      this.state.recipeServiceSetsData[this.state.activeRecipe];

    const index = currentSelectedServiceSet.indexOf(serviceSetId);
    if (index > -1) {
      currentSelectedServiceSet.splice(index, 1);
    } else {
      currentSelectedServiceSet.push(serviceSetId);
    }
    const recipeServiceSetsData: any = { ...this.state.recipeServiceSetsData };
    recipeServiceSetsData[this.state.activeRecipe] = currentSelectedServiceSet;
    this.setState({
      recipeServiceSetsData: recipeServiceSetsData,
    });
  };

  handleServiceSetDelete = (serviceSetId: string) => {
    OpenDeleteModal().then(() => {
      this.props.dispatchActions.removeServiceSet({
        restaurantuuid: this.restaurantUuid,
        uuid: serviceSetId,
      });
      this.pageMode = serviceSetOverviewMode.NONE;
      this.setState({
        activeServiceSet: "",
        pageMode: serviceSetOverviewMode.NONE,
        isUpdated: true,
      });
    });
  };

  handleServiceSetEdit = (serviceSetId: string) => {
    const selectedItem: any = this.props.storeProps.serviceSets.find(
      (serviceSet: any) => serviceSet.uuid === serviceSetId
    );

    const locale = commonService.applyLocalization(
      "restaurant",
      "name",
      selectedItem.locales
    );

    this.setState({
      isEdit: true,
      isModalOpen: true,
      itemModalData: {
        isEdit: true,
        name: locale?.name,
        description: locale?.description,
        status: selectedItem.status,
        vatClass: selectedItem.vat_class_uuid,
      },
    });
  };

  handleServiceSetRecipeSelection = (recipeId: string) => {
    const currentSelectedRecipes =
      this.state.serviceSetRecipesData[this.state.activeServiceSet];

    const index = currentSelectedRecipes.indexOf(recipeId);
    if (index > -1) {
      currentSelectedRecipes.splice(index, 1);
    } else {
      currentSelectedRecipes.push(recipeId);
    }
    const serviceSetRecipes: any = { ...this.state.serviceSetRecipesData };
    serviceSetRecipes[this.state.activeServiceSet] = currentSelectedRecipes;
    this.setState({
      serviceSetRecipesData: serviceSetRecipes,
    });
  };

  handleServiceSetRecipeEdit = (serviceSetId: string) => {
    this.pageMode = serviceSetOverviewMode.SERVICE_SET_EDIT;
    this.activeServiceSetData["recipes"] = _.cloneDeep(
      this.state.serviceSetRecipesData[serviceSetId]
    );
    this.setState({
      activeServiceSet: serviceSetId,
      pageMode: serviceSetOverviewMode.SERVICE_SET_EDIT,
    });
  };

  handleServiceSetRecipeEditCancel = (serviceSetId: string) => {
    const serviceSetRecipesData: any = { ...this.state.serviceSetRecipesData };
    serviceSetRecipesData[serviceSetId] = this.activeServiceSetData["recipes"];
    this.setState({
      pageMode: serviceSetOverviewMode.SERVICE_SET_SELECTION,
      serviceSetRecipesData: serviceSetRecipesData,
    });
    this.pageMode = serviceSetOverviewMode.SERVICE_SET_SELECTION;
    this.activeServiceSetData["recipes"] = {};
    this.setServiceSetRecipes(this.state.serviceSetRecipesClone);
  };

  handleServiceSetRecipeEditSave = (serviceSetId: string) => {
    this.showLoader();
    let minorUnit = JSON.parse(
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
    )?.minor_units;
    const serviceSetRecipeList: Array<any> =
      this.state.serviceSetRecipes.filter((recipe: any) => {
        if (recipe.uuid.indexOf("UUID_") !== -1) {
          delete recipe.uuid;
        }
        delete recipe.type;
        delete recipe.currency;
        delete recipe.sort_order;
        delete recipe.recipe_output_unit;
        recipe.price = recipe.price * Math.pow(10, minorUnit);

        return this.state.serviceSetRecipesData[serviceSetId].includes(
          recipe.recipe_uuid
        );
      });
    const payload: any = {
      credentials: {
        itemUuid: serviceSetId,
        data: { recipe: serviceSetRecipeList },
        restaurantUuid: this.restaurantUuid,
      },
    };

    servicesetDetailsService
      .updateServiceSetRecipe(RESTAURANT_REQUEST, payload)
      .then((response: any) => {
        this.showLoader(false);
        const responseData: any = handleResponse(response);
        if (responseData.ok) {
          this.serviceSetRecipeData[serviceSetId] = undefined;
          const currentState: any = { ...this.state.serviceSetRecipesData };
          currentState[serviceSetId] = undefined;
          this.getServiceSetDetails(serviceSetId);
        }

        this.showLoader(false);
      })
      .catch((error: any) => this.showLoader(false));
  };

  handleServiceSetSave = (data: any) => {
    this.showLoader();
    if (this.state.activeServiceSet !== "") {
      this.props.dispatchActions.updateServiceSet({
        data,
        restaurantuuid: this.props.match.params.uuid,
        uuid: this.state.activeServiceSet,
        items: [],
      });
    } else {
      this.props.dispatchActions.addServiceSet({
        data,
        restaurantuuid: this.props.match.params.uuid,
      });
    }
    this.onAddNewItem();
    this.pageMode = serviceSetOverviewMode.NONE;
    this.setState({
      isUpdated: true,
      activeServiceSet: "",
      pageMode: serviceSetOverviewMode.NONE,
    });
  };

  handleServiceSetCopy = (uuid: string) => {
    const payLoad = {
      credentials: {
        serviceSetUuid: uuid,
        restaurantuuid: this.props.match.params.uuid,
      },
      data: {},
    };
    copyServiceSet(RESTAURANT_REQUEST, payLoad).then((data: any) => {
      const responseData: any = handleResponse(data);
      if (responseData.ok) {
        this.setState({ isUpdated: true });
      }
    });
  };

  handleServiceSetSelection = (serviceSetId: string) => {
    if (
      this.state.activeServiceSet === serviceSetId &&
      this.pageMode === serviceSetOverviewMode.SERVICE_SET_SELECTION
    ) {
      this.setState({
        activeServiceSet: "",
        pageMode: serviceSetOverviewMode.NONE,
      });
      this.pageMode = serviceSetOverviewMode.NONE;
      return;
    } else if (this.pageMode === serviceSetOverviewMode.RECIPE_EDIT) {
      this.handleRecipeServiceSetSelections(serviceSetId);
    } else if (
      this.state.activeServiceSet !== serviceSetId &&
      this.pageMode === serviceSetOverviewMode.SERVICE_SET_EDIT
    ) {
      return;
    } else {
      if (!this.serviceSetRecipeData[serviceSetId])
        this.getServiceSetDetails(serviceSetId);
      else {
        this.setState({
          activeServiceSet: serviceSetId,
          activeRecipe: "",
          pageMode: serviceSetOverviewMode.SERVICE_SET_SELECTION,
        });

        this.pageMode = serviceSetOverviewMode.SERVICE_SET_SELECTION;
      }
    }
  };

  loadMetaData = () => {
    this.props.dispatchActions.getVatList({
      restaurantuuid: this.props.match.params.uuid,
    });
    this.props.dispatchActions.getUnits({
      restaurantuuid: this.props.match.params.uuid,
    });
    const payload: any = {
      credentials: {
        items: "storage,label,room,unit",
        restaurantuuid: this.restaurantUuid,
      },
    };

    getRestaurantItemMetaDetails(RESTAURANT_REQUEST, payload)
      .then((response: any) => {
        const responseData: any = handleResponse(response);
        if (responseData.ok) {
          const { label, room, storage } = responseData.data;
          const rooms: any = {};
          room.forEach((r: any) => {
            rooms[r.uuid] = r;
            const name: string = commonService
              .applyLocalization("restaurant", "name", r.locales)
              ["name"].toLowerCase();
            rooms[r.uuid]["nameLower"] = name.toLowerCase();
            rooms[r.uuid]["name"] = name;
          });
          storage.forEach((st: any) => {
            if (!rooms[st.room_uuid]?.["storage"]) {
              rooms[st.room_uuid]["storage"] = [];
            }
            rooms[st.room_uuid]["storage"].push(st);
          });

          this.restaurantMetaData = {
            labels: label || [],
            locations: _.sortBy(rooms, "nameLower"),
            rooms: room || [],
            storages: storage || [],
          };
        }

        this.showLoader(false);
      })
      .catch((error: any) => this.showLoader(false));
  };

  onAddNewItem = () => {
    this.setState((prevState: any) => ({
      activeServiceSet: "",
      isModalOpen: !prevState.isModalOpen,
      selectedItemId: "",
      isEdit: "",
      itemModalData: {
        name: "",
        description: "",
        status: 1,
        vatClass: {},
      },
    }));
  };

  removeStorage = (ingredientId: string, uuid: string) => {
    OpenDeleteModal()
      .then(() => {
        this.setState({ isFetching: true });
        let allRecipeStorages = _.clone(this.recipeLocationsData[ingredientId]);

        const index = _.findIndex(allRecipeStorages, function (storage: any) {
          return storage.uuid === uuid;
        });
        if (index >= 0) {
          allRecipeStorages.splice(index, 1);
          this.setState({ isStorageUpdated: true });

          this.updateRecipeStorage(allRecipeStorages, ingredientId);
        } else {
          this.setState({ isFetching: false });
        }
      })
      .catch(() => {
        return;
      });
  };

  saveRecipeStorage = (formData: any) => {
    this.setState({
      isFetching: true,
      toggleStorage: false,
      storageFormData: {},
    });
    let updatedStorage: any = [];
    const { ingredientId } = this.state.activeStorageDetails;
    let allRecipeStorages = _.clone(
      this.recipeLocationsData[ingredientId] || []
    );
    if (this.state.activeStorageDetails?.isEdit) {
      const { editData }: any = this.state.activeStorageDetails;
      const index = _.findIndex(allRecipeStorages, (storage: any) => {
        return storage.uuid === editData.uuid;
      });

      if (!!formData.main_storage && !editData.main_storage) {
        const index = _.findIndex(allRecipeStorages, (storage: any) => {
          return storage.main_storage === true;
        });
        if (index >= 0) {
          allRecipeStorages[index]["main_storage"] = false;
        }
      }
      allRecipeStorages[index]["main_storage"] = formData.main_storage;

      if (!!formData.min_quantity && Number(formData.min_quantity) >= 0) {
        allRecipeStorages[index]["min_quantity"] =
          Number(formData.min_quantity) *
          (Number(formData.conversionFactor) || 1);
      } else {
        allRecipeStorages[index]["min_quantity"] = undefined;
      }
      if (!!formData.max_quantity && Number(formData.max_quantity) > 0) {
        allRecipeStorages[index]["max_quantity"] =
          Number(formData.max_quantity) *
          (Number(formData.conversionFactor) || 1);
      } else {
        allRecipeStorages[index]["max_quantity"] = undefined;
      }
      if (!!formData.inventory_type) {
        allRecipeStorages[index]["inventory_type"] = formData.inventory_type;
      } else {
        delete allRecipeStorages[index]["inventory_type"];
      }

      const outputQty: number =
        (Number(formData.conversionFactor) || 1) * formData.output_quantity;

      allRecipeStorages[index].output_quantity = outputQty;
      allRecipeStorages[index].output_unit = formData.output_unit_uuid;

      updatedStorage = allRecipeStorages;
    } else {
      const { area, data } = this.state.activeStorageDetails;
      let storage: any = {};
      if (area === storageType.ROOM) {
        storage.room = data[storageType.ROOM];
      } else if (area === storageType.STORAGE) {
        storage.room = data[storageType.ROOM];
        storage.storage = data[storageType.STORAGE];
      } else if (area === storageType.STORAGE_RACK) {
        storage.room = data[storageType.ROOM];
        storage.storage = data[storageType.STORAGE];
        storage.storage_rack = data[storageType.STORAGE_RACK];
      } else if (area === storageType.STORAGE_PLACE) {
        storage.room = data[storageType.ROOM];
        storage.storage = data[storageType.STORAGE];
        storage.storage_rack = data[storageType.STORAGE_RACK];
        storage.storage_place = data[storageType.STORAGE_PLACE];
      }
      if (!!formData.main_storage) {
        const index = _.findIndex(allRecipeStorages, (storage: any) => {
          return storage.main_storage === true;
        });
        if (index >= 0) {
          allRecipeStorages[index]["main_storage"] = false;
        }
        storage.main_storage = true;
      }
      if (!!formData.min_quantity && Number(formData.min_quantity) >= 0) {
        storage.min_quantity =
          Number(formData.min_quantity) *
          (Number(formData.conversionFactor) || 1);
      }
      if (!!formData.max_quantity && Number(formData.max_quantity) > 0) {
        storage.max_quantity =
          Number(formData.max_quantity) *
          (Number(formData.conversionFactor) || 1);
      }
      if (!!formData.inventory_type) {
        storage.inventory_type = formData.inventory_type;
      } else {
        delete storage.inventory_type;
      }

      const outputQty: number =
        (Number(formData.conversionFactor) || 1) * formData.output_quantity;
      storage.output_quantity = outputQty;
      storage.output_unit = formData.output_unit_uuid;

      updatedStorage = [...allRecipeStorages, ...[storage]];
    }
    this.updateRecipeStorage(updatedStorage, ingredientId);
  };

  showLoader = (status: boolean = true) => {
    this.setState({ isFetching: status });
  };

  toggleStorageMenu = () => {
    this.setState({
      isStorageModalOpen: !this.state.isStorageModalOpen,
    });
  };

  updateRecipeStorage(data: any, ingredientId: string) {
    this.showLoader();
    const payLoad = {
      credentials: {
        recipeuuid: ingredientId,
        restaurantuuid: this.props.match.params.uuid,
        data: { storage: data },
      },
    };

    updateRecipeStorage(RESTAURANT_REQUEST, payLoad)
      .then((data: any) => {
        const responseData: any = handleResponse(data);
        if (responseData.ok) {
          this.setState({
            isStorageModalOpen: false,
          });
          this.getRecipeLocations(ingredientId);
        } else {
          this.setState({ isFetching: false });
          commonService.toastService(
            this.props.t("storage.storageUpdateFailed"),
            "danger"
          );
        }

        this.showLoader(false);
      })
      .catch((error: any) => this.showLoader(false));
  }

  render() {
    const { t } = this.props;
    const serviceSetActions: any = {
      handleServiceSetCancel: this.handleServiceSetRecipeEditCancel,
      handleServiceSetDelete: this.handleServiceSetDelete,
      handleServiceSetRecipeEdit: this.handleServiceSetRecipeEdit,
      handleServiceSetEdit: this.handleServiceSetEdit,
      handleServiceSetSave: this.handleServiceSetRecipeEditSave,
      handleServiceSetSelection: this.handleServiceSetSelection,
      handleServiceSetCopy: this.handleServiceSetCopy,
    };
    const serviceSetMetaData: any = {
      activeRecipe: this.state.activeRecipe,
      activeServiceSet: this.state.activeServiceSet,
      recipeServiceSetsData: this.state.recipeServiceSetsData,
      pageMode: this.state.pageMode,
      restaurantId: this.restaurantUuid,
    };
    const recipeListActions: any = {
      getSupplierDetails: this.getSupplierDetails,
      handleRecipeCancel: this.handleRecipeEditCancel,
      handleRecipeEdit: this.handleRecipeEdit,
      handleRecipeSave: this.handleRecipeEditSave,
      handleRecipeSelection: this.handleRecipeSelection,
      setServiceSetRecipes: this.setServiceSetRecipes.bind(this),
      storageActions: {
        addNewStorage: this.addNewStorage,
        editStorage: this.editStorage,
        removeStorage: this.removeStorage,
        getBestMatchUnits: this.getBestMatchUnits,
      },
    };

    const recipeListMetaData: any = {
      activeRecipe: this.state.activeRecipe,
      activeServiceSet: this.state.activeServiceSet,
      allLocations: this.restaurantMetaData.locations,
      pageMode: this.state.pageMode,
      recipesLocationsData: this.recipeLocationsData,
      serviceSetRecipesData: this.state.serviceSetRecipesData,
    };

    return (
      <div>
        <ModalFactory />
        <LoaderComponent
          display={this.state.isFetching || this.props.storeProps.isFetching}
        />

        <ManageStorageStorage
          toggleStorageMenu={this.toggleStorageMenu}
          toggleStorage={this.state.isStorageModalOpen}
          saveRecipeStorage={this.saveRecipeStorage}
          storageFormData={this.state.storageFormData}
          units={
            this.state.isStorageModalOpen
              ? this.getRelatedUnitsForStorage()
              : []
          }
        />

        <ServiceSetAddModal
          formData={this.state.itemModalData}
          handleCancel={this.onAddNewItem}
          handleSubmit={this.handleServiceSetSave}
          isEdit={this.state.isEdit}
          isModalOpen={this.state.isModalOpen}
          restaurantLang={this.props.storeProps.restaurantLang}
          textLabels={this.getAddModalLabel()}
          vatList={this.props.storeProps.vat}
        />
        {/* 
        
   <ServiceSetAddRooms 
        //   formData={this.state.itemModalData}
        //   handleCancel={this.addRoomToggle}
        //   handleTimeSubmit={this.handleTimeSubmit}
        //   isRoomModal={isRoomModal}
        //   roomList={this.props.rooms}
        //   weekDays={this.state.weekDays}
        //   title={this.state.addLocationTitle}
        />*/}
        <div className="container-fluid">
          <ConceptHeaderComponent concept={"concept"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <RestaurantNavComponent
                display={"service-sets"}
                restaurantuuid={this.restaurantUuid}
              />
            </Col>
            <Col xl={10} lg={9}>
              {" "}
              <div className="d-flex  justify-content-between">
                <Link
                  to="#"
                  color="info"
                  className="unit-add mr-2"
                  onClick={this.onAddNewItem}
                >
                  {t("serviceSet.addServiceSet")}
                </Link>
                <Link
                  to={`/restaurant/${this.restaurantUuid}/service-sets-classic`}
                  className="mx-2"
                >
                  <FontAwesomeIcon
                    className={" b-color-green mt-2 mx-2"}
                    style={{ fontSize: 20 }}
                    icon={faList}
                  />
                </Link>
              </div>
              <div className="white-box mb-3 b-service-set-overview">
                <Row>
                  <Col md={6}>
                    <h4>{t("serviceSet.serviceSets")}</h4>
                    <ServiceSetOverviewListWrapper
                      actions={serviceSetActions}
                      metaData={serviceSetMetaData}
                      serviceSets={
                        Object.keys(this.props.storeProps.serviceSets)
                          .length === 0
                          ? []
                          : this.props.storeProps.serviceSets
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <h4>{t("choiceItem.items")}</h4>
                    <ServiceSetOverviewItemListWrapper
                      actions={recipeListActions}
                      metaData={recipeListMetaData}
                      allRecipes={
                        Object.keys(this.props.storeProps.allRecipes).length ===
                        0
                          ? []
                          : this.props.storeProps.allRecipes
                      }
                      labels={
                        Object.keys(this.restaurantMetaData?.labels).length ===
                        0
                          ? []
                          : this.restaurantMetaData?.labels
                      }
                      serviceSetRecipes={this.state.serviceSetRecipes}
                      units={this.props.storeProps.units}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.allRecipeItems.isFetching ||
    state.servicesetadd.isFetching ||
    state.servicesetremove.isFetching ||
    state.servicesetupdate.isFetching ||
    state.units.isFetching ||
    state.serviceset.isFetching;
  let failure =
    state.allRecipeItems.failure ||
    state.servicesetadd.failure ||
    state.servicesetremove.failure ||
    state.servicesetupdate.failure ||
    state.units.failure ||
    state.serviceset.failure;
  return {
    storeProps: {
      allRecipes: state.allRecipeItems.data,
      failure: failure,
      isFetching: isFetching,
      restaurantLang: commonService.getRestaurantDetails()?.languages || [],
      serviceSets: state.serviceset.data,
      units: state.units.data,
      vat: state.vatClass.data,
    },
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    dispatchActions: {
      addServiceSet: (credentials: any) => {
        dispatch(servicesetAdd(credentials));
      },
      getAllRecipes: (credentials: any) => {
        dispatch(recipeItemsRequest(credentials));
      },
      getServiceSet: (credentials: any) => {
        dispatch(servicesetRequest(credentials));
      },
      getUnits: (credentials: any) => {
        dispatch(unitsRequest(credentials));
      },
      getVatList: (credentials: any) => {
        dispatch(vatRequest(credentials));
      },
      removeServiceSet: (credentials: any) => {
        dispatch(servicesetRemove(credentials));
      },
      updateServiceSet: (credentials: any) => {
        dispatch(servicesetUpdate(credentials));
      },
    },
  };
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(ServiceSetPageOverviewPage))
);

import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Select from "react-select";
import "nouislider/distribute/nouislider.css";
import "../../../styles/tag-assign.scss";
import { useTranslation } from "react-i18next";
import CurrencyInput from "react-currency-input-field";
import commonService from "../../../services/common.service";

interface Props {
  showModal: boolean;
  updateServiceSet: Function;
  serviceSetData: any;
  serviceSetList: Array<any>;
  toggleModal: Function;
  units: Array<any>;
}

export default function ServiceSetAssignCard(props: Props) {
  const { t, i18n } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);
  const [serviceSet, setServiceSet] = useState({});
  const [selectedServiceSetId, setSelectedServiceSet] = useState("");
  const [price, setPrice] = useState("0");
  const [status, setStatus] = useState(true);
  const [offeredUnit, setOfferedUnit] = useState("");
  const [relatedUnits, setRelatedUnits] = useState<Array<any>>([]);

  useEffect(() => {
    setIsDisabled(getSaveButtonStatus());

    return () => {};
  }, []);

  useEffect(() => {
    setRelatedUnits(
      commonService.getRelateUnitDropdowns(
        props.serviceSetData.itemOutputUnitId,
        props?.units,
        props?.units,
        1,
        props.serviceSetData.itemId
      ) || []
    );
    if (props.serviceSetData.serviceSet?.uuid) {
      setOfferedUnit(props.serviceSetData.serviceSet?.offered_unit);
    } else {
      setOfferedUnit(props.serviceSetData.itemOutputUnitId);
    }
  }, [props.units, props.serviceSetData]);

  const getSaveButtonStatus = () => {
    return true;
  };

  const handleChange = (value: string | boolean | any, area: string) => {
    if (area === "serviceSet") {
      setServiceSet(value);
    } else if (area === "price") {
      setPrice(value);
    } else if (area === "status") {
      setStatus(value);
    }
    return true;
  };

  const onModalOpen = () => {
    if (props.serviceSetData.type === "NEW") {
      setPrice("0");
      setServiceSet({});
    } else {
      setPrice(
        commonService.displayDigitalAmount(
          props.serviceSetData.serviceSet.price || 0
        )
      );
      setServiceSet({
        label: props.serviceSetData.serviceSet.name,
        value: props.serviceSetData.serviceSet.service_set_uuid,
      });
      setSelectedServiceSet(props.serviceSetData.serviceSet.uuid);
      setStatus(!!props.serviceSetData.serviceSet.status);
    }
  };

  const toggleModal = () => {
    setServiceSet({});
    setPrice("0");
    props.toggleModal();
  };

  const disableButton = () => {
    return (
      price === "" || Object.keys(serviceSet).length === 0 || offeredUnit === ""
    );
  };

  const saveServiceset = () => {
    props.updateServiceSet(
      {
        serviceSet,
        price,
        itemId: props.serviceSetData.itemId,
        selectedServiceSetId,
        status,
        offeredUnit,
      },
      props.serviceSetData.type
    );
  };

  const minorUnit = JSON.parse(
    localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
  )?.minor_units;
  const currencyCode = JSON.parse(
    localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
  )?.currency_code;

  const restaurantLanguage: any = localStorage.getItem("i18nextLng");
  return (
    <Modal isOpen={props.showModal} onOpened={onModalOpen}>
      <ModalHeader toggle={toggleModal} className="modal-header">
        {t("serviceSet.addToServiceSet")}
      </ModalHeader>
      <ModalBody>
        <Form className="tag-assign">
          <FormGroup row className="p-0">
            <Label className="text-right col-sm-4 col-form-label" for="tagName">
              {t("serviceSet.serviceSet")}
              <span className="required-star-style">*</span>
            </Label>
            <Col sm={8}>
              <Select
                maxMenuHeight={200}
                name="tagName"
                onChange={(val: any) => {
                  handleChange(val, "serviceSet");
                }}
                options={props.serviceSetList}
                value={serviceSet}
                placeholder={t("serviceSet.serviceSet")}
                isDisabled={props.serviceSetData.type === "EDIT"}
              />
            </Col>
          </FormGroup>
          <FormGroup row className="p-0">
            <Label className="text-right" for="tagValue" sm={4}>
              {t("common.offeredUnit")}
              <span className="required-star-style">*</span>
            </Label>
            <Col sm={8} className={"pt-2"}>
              <Row className="p-0">
                <Col lg={7} md={7}>
                  <Input
                    type="select"
                    id="offeredUnit"
                    required
                    name="offeredUnit"
                    value={offeredUnit}
                    onChange={(e) => {
                      setOfferedUnit(e.target.value);
                    }}
                  >
                    <option value="">{t("unit.selectUnits")} ...</option>
                    {Object.keys(relatedUnits || {}).length > 0 &&
                      relatedUnits.map((unit: any, index: number) => (
                        <option value={unit.value} key={index}>
                          {unit.label}
                        </option>
                      ))}
                  </Input>
                </Col>
                <Col lg={5} md={5}></Col>
              </Row>
            </Col>
          </FormGroup>
          <FormGroup row className="p-0">
            <Label className="text-right" for="tagValue" sm={4}>
              {t("common.price")}
            </Label>
            <Col sm={8} className={"pt-2"}>
              <CurrencyInput
                intlConfig={{
                  locale: restaurantLanguage,
                  currency: currencyCode,
                }}
                className="form-control"
                id={"itemPrice_"}
                name={"itemPrice_"}
                value={price}
                onValueChange={(value: any) => handleChange(value, "price")}
                decimalScale={minorUnit}
                decimalsLimit={minorUnit}
                prefix=" "
              />
            </Col>
          </FormGroup>
          <FormGroup row className="p-0">
            <Label className="text-right" for="tagValue" sm={4}>
              {t("common.status")}
            </Label>
            <Col sm={8} className={"pt-2"}>
              <Label for="status" className="d-flex">
                <CustomInput
                  checked={status}
                  id="override"
                  label=""
                  name="override"
                  onChange={(e: any) =>
                    handleChange(e.target.checked, "status")
                  }
                  type="switch"
                />

                {!!status ? t("common.active") : t("common.inactive")}
              </Label>
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          onClick={saveServiceset}
          disabled={disableButton()}
        >
          {t("common.save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

import {
  faEdit,
  faTrashAlt,
  faSave,
  faTimes,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import "../../../styles/card-item.scss";
import ConditionalLinkArea from "../../conditionalLinkArea/conditionalLinkArea";
import { OpenDeleteModal } from "../delete-card/delete-modal";

class CardItemControlField extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      mouseOver: false,
    };
  }

  myOnDeleteClick = () => {
    OpenDeleteModal().then(() => {
      this.props.deleteCallback();
    });
  };

  onCancel = () => {
    if (this.props.cancelCallback) {
      this.props.cancelCallback();
    } else {
      this.props.cardItemSelectionData.cardItem.isNewCardItem
        ? this.props.cardItemSelectionData.deselectCardItem()
        : this.props.selectCallback();
    }
  };

  render() {
    return !this.props.readOnly ? (
      <div
        className={"bf-btn-wrapper "}
        style={{ justifyContent: "flex-end", display: "flex" }}
      >
        <Translation>
          {(t, { i18n }) => (
            <ConditionalLinkArea
              type="span"
              activeLink={
                this.props.cardItemSelectionData.selectionState.isItemSelected
              }
              hide={
                this.props.hideEdit ||
                !(
                  this.props.cardItemSelectionData.selectionState
                    .isItemSelected &&
                  this.props.cardItemSelectionData.selectionState.mode ===
                    "default"
                ) ||
                this.props.cardItemSelectionData.cardItem.isNewCardItem
              }
              className={
                this.props.className +
                " " +
                this.props.cardItemSelectionData.className +
                " card-item-button"
              }
              onClick={this.props.editCallback}
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.edit")}
            >
              <FontAwesomeIcon
                style={{ fontSize: 20, marginRight: 10, marginTop: 5 }}
                icon={faEdit}
              />
            </ConditionalLinkArea>
          )}
        </Translation>
        <Translation>
          {(t, { i18n }) => (
            <ConditionalLinkArea
              type="span"
              activeLink={
                this.props.cardItemSelectionData.selectionState.isItemSelected
              }
              hide={
                this.props.hideDelete ||
                !(
                  this.props.cardItemSelectionData.selectionState
                    .isItemSelected &&
                  this.props.cardItemSelectionData.selectionState.mode ===
                    "default"
                ) ||
                this.props.cardItemSelectionData.cardItem.isNewCardItem
              }
              className={
                this.props.className +
                " " +
                this.props.cardItemSelectionData.className +
                " card-item-button"
              }
              onClick={
                this.props.onDeleteClick
                  ? this.props.onDeleteClick
                  : this.myOnDeleteClick
              }
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.delete")}
            >
              <FontAwesomeIcon
                style={{ fontSize: 20, marginRight: 10, marginTop: 5 }}
                icon={faTrashAlt}
              />
            </ConditionalLinkArea>
          )}
        </Translation>
        <Translation>
          {(t, { i18n }) => (
            <ConditionalLinkArea
              type="span"
              activeLink={
                this.props.cardItemSelectionData.selectionState.isItemSelected
              }
              hide={
                !this.props.cardItemSelectionData.selectionState.isBeingEdited
              }
              className={
                this.props.className +
                " " +
                this.props.cardItemSelectionData.className +
                " card-item-button"
              }
              onClick={this.props.saveCallback}
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.save")}
            >
              <FontAwesomeIcon
                style={{ fontSize: 20, marginRight: 10, marginTop: 5 }}
                icon={!this.props.saveButton ? faCheck : faSave}
              />
            </ConditionalLinkArea>
          )}
        </Translation>
        <Translation>
          {(t, { i18n }) => (
            <ConditionalLinkArea
              type="span"
              activeLink={
                this.props.cardItemSelectionData.selectionState.isItemSelected
              }
              hide={
                !this.props.cardItemSelectionData.selectionState.isBeingEdited
              }
              className={
                this.props.className +
                " " +
                this.props.cardItemSelectionData.className +
                " card-item-button"
              }
              onClick={this.onCancel}
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.cancel")}
            >
              <FontAwesomeIcon
                style={{ fontSize: 20, marginRight: 10, marginTop: 5 }}
                icon={faTimes}
              />
            </ConditionalLinkArea>
          )}
        </Translation>
      </div>
    ) : (
      ""
    );
  }
}

export { CardItemControlField };

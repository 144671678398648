import React, { Component } from "react";
import "../../../styles/card-item.scss";
import ConditionalLinkArea from "../../conditionalLinkArea/conditionalLinkArea";
import { CardItemControlField } from "./card-item-control-field";
import { NewCardItemContent } from "./new-card-item-content";

class CardItemCardComponent extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      mouseOver: false,
    };
    this.props.cardItemSelectionData.setOnDeselectCallback(this.handleMouseOut);
  }

  public handleMouseIn = (e: any) => {
    this.setState({ mouseOver: true });
    e.stopPropagation();
  };

  public handleMouseOut = (e: any = null) => {
    this.setState({ mouseOver: false });
  };

  render() {
    return (
      <ConditionalLinkArea
        disabled={false}
        onMouseDown={
          this.props.cardItemSelectionData.cardItem.isNewCardItem &&
          !this.props.readOnly
            ? this.props.editCallback
              ? this.props.editCallback
              : this.props.cardItemSelectionData.editCardItem
            : this.props.selectCallback
            ? this.props.selectCallback
            : this.props.cardItemSelectionData.selectCardItem
        }
        activeLink={
          this.props.cardItemSelectionData.selectionState.canBeSelected
        }
        className={
          this.props.className +
          (this.state.mouseOver ? " hover " : " ") +
          this.props.cardItemSelectionData.className +
          " card-component"
        }
        preventDefault={false}
        stopPropagation={true}
        onMouseOver={this.handleMouseIn}
        onMouseOut={this.handleMouseOut}
      >
        <CardItemControlField
          saveButton={this.props.saveButton}
          cardItemSelectionData={this.props.cardItemSelectionData}
          className={this.props.className}
          readOnly={this.props.readOnly}
          cancelCallback={this.props.cancelCallback}
          deleteCallback={this.props.deleteCallback}
          onDeleteClick={this.props.onDeleteClick}
          hideDelete={this.props?.hideDelete ? this.props.hideDelete : false}
          hideEdit={this.props?.hideEdit ? this.props.hideEdit : false}
          editCallback={
            this.props.editCallback
              ? this.props.editCallback
              : this.props.cardItemSelectionData.editCardItem
          }
          selectCallback={
            this.props.selectCallback
              ? this.props.selectCallback
              : this.props.cardItemSelectionData.selectCardItem
          }
          saveCallback={this.props.saveCallback}
        />
        {this.props.cardItemSelectionData.cardItem.isNewCardItem &&
        !this.props.cardItemSelectionData.selectionState.isBeingEdited ? (
          <NewCardItemContent newTaskWithIng={this.props.newTaskWithIng} />
        ) : (
          this.props.children
        )}
      </ConditionalLinkArea>
    );
  }
}

export { CardItemCardComponent };

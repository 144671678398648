import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import Nouislider from "nouislider-react";
import Select from "react-select";

import "nouislider/distribute/nouislider.css";
import "../../../styles/tag-assign.scss";

import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Editor } from "@tinymce/tinymce-react";

export default function TagAssignCard(props: any) {
  const { t, i18n } = useTranslation();
  const [isFreeText, setIsFreeText] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const editorRef: any = useRef(null);

  useEffect(() => {
    setIsDisabled(getSaveButtonStatus());

    return () => {};
  }, []);

  // TODO Need cleanup due to SR-1044 changes
  useEffect(() => {
    if (
      props.state.tagName?.meta?.type === 3 &&
      (props.state.moreValues?.length === 0 || !props.state.moreValues)
    )
      setIsFreeText(true);
    else setIsFreeText(false);
  });
  const onEditorChange = (value: any) => {
    props.handleTagChange(value, "freeText", false, true);
  };

  const getSaveButtonStatus = () => {
    if (isFreeText || props.state.override) {
      return false;
    } else {
      return props.state.tagName?.meta?.type === 1
        ? !props.state.defaultValue
        : props.state.tagName?.meta?.type === 3
        ? (!props.state.defaultValue && !isFreeText) ||
          (props.state.moreValues?.some((item: any) => item.value === "") &&
            !isFreeText)
        : false;
    }
  };

  const onModalOpen = () => {
    if (
      props.state.tagName?.meta?.type === 3 &&
      props.state.moreValues?.length === 0
    ) {
      props.handleTagChange(
        props.state.defaultValue.value,
        "freeText",
        true,
        props.state.richText
      );
      setIsFreeText(true);
    }
  };

  const toggleModal = () => {
    setIsFreeText(false);
    props.addTagModal();
  };

  const disableButton = () => {
    if (
      !props.state.tagName ||
      isDisabled ||
      props.state.defaultError ||
      (!props.state.override &&
        props.state.tagName?.meta?.type === 3 &&
        props.state.moreValues?.length > 0 &&
        (props.state?.tagValue?.filter((item: any) => item.value !== "")
          ?.length === 0 ||
          Object.keys(props.state.defaultValue || "{}")?.length === 0)) ||
      (!props.state.override && props.state.defaultValue === "") ||
      (!props.state.override &&
        props.state.tagName?.meta?.type === 1 &&
        props.state.defaultValue === "") ||
      (!props.state.override &&
        props.state.defaultValue === "" &&
        props.state.tagName?.meta?.type === 4)
    ) {
      return true;
    }
  };

  const getDefaultOptions = () => {
    const options = [
      {
        label: t("common.select"),
        value: "",
      },
    ];
    if (props.state.tagValue) {
      props.state.tagValue
        ?.filter((item: any) => !!item.value)
        ?.forEach((item: any) => {
          options.push({ label: item.value, value: item.value });
        });
    }
    return options;
  };

  return (
    <Modal
      isOpen={props.showAddTag}
      onOpened={onModalOpen}
      size={props.state.richText ? "xl" : "md"}
    >
      <ModalHeader toggle={toggleModal} className="modal-header">
        {t("tag.assignTag")}
      </ModalHeader>
      <ModalBody>
        <Form className="tag-assign">
          <FormGroup row className="p-0">
            <Label
              className="text-right"
              for="tagName"
              sm={props.state.richText ? 2 : 4}
            >
              {t("tag.tag")}
              <span className="required-star-style">*</span>
            </Label>
            <Col sm={8}>
              <Select
                maxMenuHeight={200}
                name="tagName"
                onChange={(val: any) => {
                  props.handleTagChange(val, "tagName");
                }}
                options={
                  props.type !== "ingredient" &&
                  Object.keys(props.state?.recipedetaildata).length > 0
                    ? props.state.tagOptions?.filter((item: any) => {
                        return !props.state?.recipedetaildata
                          ?.filter(
                            (recipe: any) => recipe.uuid === props.recipeId
                          )[0]
                          ?.tags?.find((element: any) => {
                            if (item.value === props.state.tagId) return false;
                            else return element.tag.uuid === item.value;
                          });
                      })
                    : props.state.tagOptions?.filter((item: any) => {
                        return !props.ingredientTags?.find((element: any) => {
                          if (item.value === props.state.tagId) return false;
                          else return element.tag.uuid === item.value;
                        });
                      })
                }
                value={props.state.tagName}
              />
            </Col>
          </FormGroup>
          {props.state.tagName?.meta?.type &&
            props.state.tagName?.meta?.type !== 2 &&
            props.state.tagName?.meta?.type !== 4 &&
            !isFreeText && (
              <FormGroup row className="p-0">
                <Label className="text-right" for="tagValue" sm={4}>
                  {t("common.value")}
                  <span className="required-star-style">*</span>
                </Label>
                <Col sm={8} className="pt-2">
                  {props.state.tagName?.meta?.type === 1 ? (
                    <>
                      <Nouislider
                        connect
                        onUpdate={(val: any) =>
                          props.handleTagChange(val, "range")
                        }
                        range={{
                          min: props.state.tagName?.meta?.from,
                          max: props.state.tagName?.meta?.to,
                        }}
                        start={[
                          props.state.tagValue[0],
                          props.state.tagValue[1],
                        ]}
                        step={1}
                      />
                      <div className="mt-3 bf-font-size-12">
                        <span className="">{t("common.min")} :</span>{" "}
                        {Number(props.state.tagValue[0])},
                        <span className="bf-font-size-12">
                          {t("common.max")} :
                        </span>{" "}
                        {Number(props.state.tagValue[1])}
                      </div>
                    </>
                  ) : (
                    props.state.moreValues?.length > 0 && (
                      <Select
                        maxMenuHeight={200}
                        name="tagValue"
                        onChange={(val: any) => {
                          props.handleTagChange(val, "text");
                        }}
                        options={props.state.moreValues
                          ?.filter((item: any) => !!item.value)
                          ?.map((item: any) => ({
                            label: item.value,
                            value: item.value,
                          }))}
                        value={props.state.tagValue}
                        isMulti
                      />
                    )
                  )}
                </Col>
              </FormGroup>
            )}
          {props.state.tagName?.meta?.type !== 4 && (
            <FormGroup row className="p-0">
              {props.state.tagName?.meta?.type && (
                <>
                  <Label
                    className="text-right"
                    for="tagValue"
                    sm={props.state.richText ? 2 : 4}
                  >
                    {t("common.default")}
                    {!props.state.override &&
                      (props.state.tagName?.meta?.type === 1 ||
                        (props.state.tagName?.meta?.type === 3 &&
                          props.state.moreValues?.filter(
                            (item: any) => !!item.value
                          ).length > 0) ||
                        (!props.state.override && isFreeText)) && (
                        <span className="required-star-style">*</span>
                      )}
                  </Label>
                  <Col
                    sm={8}
                    className={
                      props.state.tagName?.meta?.type === 2
                        ? "d-flex pt-2"
                        : "pt-2"
                    }
                  >
                    {props.state.tagName?.meta?.type === 1 && (
                      <>
                        <Input
                          name="defaultValue"
                          onChange={(e) => {
                            isFreeText
                              ? props.handleTagChange(e, "freeText")
                              : props.handleChange(e);
                          }}
                          type="text"
                          value={props.state.defaultValue}
                        />
                      </>
                    )}

                    {isFreeText && (
                      <>
                        <a
                          className="bf-font-size-14 cursor-pointer mt-3"
                          onClick={() => {
                            if (!props.state.richText)
                              props.setAdvanceEdits(true);
                            else props.setAdvanceEdits(false);
                          }}
                        >
                          {props.state.richText
                            ? t("common.simpleEdits")
                            : t("common.advancedEdits")}
                        </a>
                        {!props.state.richText ? (
                          <textarea
                            className="form-control"
                            name="defaultValue"
                            onChange={(e) => {
                              isFreeText
                                ? props.handleTagChange(e, "freeText")
                                : props.handleChange(e);
                            }}
                            value={props.state.defaultValue}
                          />
                        ) : (
                          <Editor
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            apiKey={process.env.REACT_APP_TINY_MCE_KEY}
                            value={props.state.defaultValue}
                            init={{
                              height: 500,
                              menubar: false,
                              plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code codesample fullscreen",
                                "insertdatetime media table paste code help wordcount",
                              ],
                              toolbar:
                                "undo redo | formatselect | " +
                                "bold italic backcolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist  | " +
                                " code codesample  | removeformat | help",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                              codesample_languages: [
                                { text: "JavaScript", value: "javascript" },
                                { text: "HTML/XML", value: "markup" },
                              ],
                            }}
                            onEditorChange={(newValue, editor) => {
                              onEditorChange(newValue);
                            }}
                          />
                        )}
                      </>
                    )}

                    {props.state.tagName?.meta?.type === 2 && (
                      <>
                        <CustomInput
                          checked={props.state.defaultValue}
                          id="defaultValue"
                          label=""
                          name="defaultValue"
                          onChange={props.handleChange}
                          type="switch"
                        />
                        <span className="ml-2">
                          {String(props.state.defaultValue)
                            .charAt(0)
                            .toUpperCase() +
                            String(props.state.defaultValue).slice(1)}
                        </span>
                      </>
                    )}
                    {props.state.tagName?.meta?.type === 3 && !isFreeText && (
                      <Select
                        maxMenuHeight={200}
                        name="defaultValue"
                        onChange={(val: any) =>
                          props.handleTagChange(val, "defaultValue")
                        }
                        options={getDefaultOptions()}
                        value={props.state.defaultValue}
                      />
                    )}
                    <span className="bf-font-size-12 text-danger">
                      {props.state.defaultError}
                    </span>
                  </Col>
                </>
              )}
            </FormGroup>
          )}
          {props.state.tagName?.meta?.type &&
            props.state.tagName?.meta?.type !== 4 && (
              <FormGroup row className="p-0">
                <Label
                  className={"text-right"}
                  for="tagValue"
                  sm={props.state.richText ? 2 : 4}
                >
                  {t("common.override")}
                </Label>
                <Col sm={8} className="d-flex pt-2">
                  <CustomInput
                    checked={props.state.override}
                    id="override"
                    label=""
                    name="override"
                    onChange={props.handleChange}
                    type="switch"
                  />
                  <span className="ml-2">
                    {" "}
                    {String(props.state.override).charAt(0).toUpperCase() +
                      String(props.state.override).slice(1)}
                  </span>
                </Col>
              </FormGroup>
            )}
          {props.state.tagName?.meta?.type === 4 && (
            <FormGroup row className="p-0">
              <Label
                className={
                  props.state.tagName?.meta?.type !== 2
                    ? "text-right"
                    : "text-right"
                }
                for="tagValue"
                sm={4}
              >
                {t("contentManagement.content")}
                <span className="required-star-style">*</span>
              </Label>
              <Col sm={8} className="">
                <Select
                  maxMenuHeight={200}
                  name="content"
                  onChange={(val: any) => props.handleTagChange(val, "content")}
                  options={props.contentList}
                  value={props.state.defaultValue}
                />
              </Col>
            </FormGroup>
          )}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          onClick={() => {
            props.saveTag(props.state.tagId === "" ? "add" : "edit");
          }}
          disabled={disableButton()}
        >
          {t("common.save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
